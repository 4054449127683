<template>
  <div class="storyblok-intro-text">
    <UtilWrapperLayout>
      <UtilGrid>
        <div class="storyblok-intro-text__wrapper">
          <div class="storyblok-intro-text__section__heading">
            <AtomHeading
              v-if="title"
              class="storyblok-intro-text__heading"
              :text="title"
              font-size="h2"
              html-tag="h2"
            />
            <AtomLead
              v-if="text"
              :text="text"
            />
          </div>

          <AtomImage
            v-if="!imgLink?.cached_url && img?.filename && !items"
            class="storyblok-intro-text__image"
            :image="img"
            provider="storyblok"
          />

          <UtilRouteLink
            v-if="imgLink?.id && img?.filename && !items"
            class="form-link__link"
            :link="imgLink.cached_url"
          >
            <AtomImage
              v-if="img?.filename"
              class="storyblok-intro-text__image"
              :image="img"
              provider="storyblok"
            />
          </UtilRouteLink>
          <div
            v-else-if="img?.filename && items.length > 0"
            class="form-link__link"
          >
            <AtomImage
              v-if="img?.filename"
              class="storyblok-intro-text__image"
              :image="img"
              provider="storyblok"
              @click="modalboxStore.openModalbox"
              @keypress="modalboxStore.openModalbox"
            />
            <Transition name="fade-in">
              <BlockMobalboxOverlay
                v-if="modalboxStore.isOpen"
                :items="mappedItems"
              />
            </Transition>
          </div>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const props = defineProps({
    text: {
        type: String,
        default: '',
    },

    title: {
        type: String,
        default: '',
    },

    img: {
        type: Object,
        default: () => {},
    },

    imgLink: {
        type: Object,
        default: () => {},
    },

    overlay: {
        type: Array,
        default: () => [],
    },
});

const {
    overlay,
} = toRefs(props);

const modalboxStore = useModalboxStore();
const storyblokApi = useStoryblokApi();
const items = computed(() => overlay?.value[0]?.items);

const mappedItems = computed(() => items.value.map((item) => ({
    ...item,
    text: storyblokApi?.richTextResolver.render(item.text),
    // map ctas to the format we need
    ctas: item.ctas.map((cta) => ({
        ...cta,
        link: cta.link.cached_url || cta.link,
    })),
})));
</script>

<style lang="scss" scoped>
.storyblok-intro-text__wrapper {
    @include fluid('gap', 20px, 30px);
    @include default-content-columns;

    display: flex;
    margin-bottom: 40px;

    @include mobile {
        flex-direction: column;
    }
}

.storyblok-intro-text__heading {
    @include fluid('margin-bottom', 10px, 30px);
}

.storyblok-intro-text__image {
    min-width: 238px;
    cursor: pointer;

    @include mobile {
        min-width: 135px;
        max-width: fit-content;
    }
}
</style>
