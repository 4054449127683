<template>
  <div class="storyblok-heading">
    <UtilWrapperLayout>
      <UtilGrid>
        <AtomHeading
          class="storyblok-heading__title"
          v-bind="{ ...props }"
          :font-size="fontSize"
          :html-tag="htmlTag"
        />
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const props = defineProps({
    text: {
        type: String,
        default: 'Example heading',
        validator: (value) => value.length > 0,
    },
    htmlTag: {
        type: String,
        default: 'h2',
        validator: (value) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div'].includes(value),
    },
    fontSize: {
        type: String,
        default: 'h3',
        validator: (value) => ['h1', 'h2', 'h3', 'h3-atom', 'h4-atom'].includes(value),
    },
});
</script>

<style lang="scss">
.storyblok-heading {
    @include fluid('margin-bottom', 20px, 40px);
    & + .storyblok-heading {
        @include fluid('--negative-margin', 10px, 20px);
        margin-top: calc(var(--negative-margin) * -1);
    }
    & + .storyblok-text-richtext {
        @include fluid('--negative-margin', 10px, 20px);
        margin-top: calc(var(--negative-margin) * -1);
    }
}
.storyblok-heading__title {
    @include default-content-columns;
}
</style>
