<template>
  <div class="storyblok-projects-block">
    <UtilWrapperLayout class="delete-padding">
      <UtilGrid>
        <div class="storyblok-projects-block__content is-medium-wide">
          <div class="storyblok-projects-block__filter-section">
            <div v-if="title" class="storyblok-projects-block__title">
              <h3>{{ title }}</h3>
            </div>
            <BlockFilter
              v-if="relations"
              class="storyblok-projects-block__filter"
              :title="useStoryblokTranslation('general', 'filter')"
              :dropdowns="mappedDropdowns"
            />
          </div>
          <BlockFilteredProjects
            class="storyblok-projects-block__block"
            :projects="projectsData"
          />
          <AtomButton
            v-if="showLoadMore === true"
            class="storyblok-projects-block__more-button"
            :text="useStoryblokTranslation('general', 'more_projects')"
            @click="loadMore()"
          />
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>
<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    department: {
        type: String,
        default: '',
    },
});

const filterDepartmentParams = {
    label: useStoryblokTranslation('general', 'platform'),
    content_type: 'Department',
    position: 1,
    type: 'department',
    storyblok_component: 'Department',
    field_type: 'multiple_option',
};

const filterParams = computed(() => ([
    {
        label: useStoryblokTranslation('general', 'project_type'),
        content_type: 'Division',
        position: 2,
        type: 'type-of-project',
        storyblok_component: 'Type',
        field_type: 'multiple_option',
    }, {
        label: useStoryblokTranslation('general', 'cantons'),
        content_type: 'Canton',
        position: 4,
        type: 'canton',
        storyblok_component: 'Kanton',
        field_type: 'multiple_option',
    },
]));

const sortParams = computed(() => ([
    {
        label: useStoryblokTranslation('general', 'sort_title'),
        position: 3,
        type: 'sort-title',
        field_type: 'single_option',
        options: [
            {
                name: useStoryblokTranslation('general', 'sort_asc'),
                value: 'name:asc',
                type: 'sort-title',
            },
            {
                name: useStoryblokTranslation('general', 'sort_desc'),
                value: 'name:desc',
                type: 'sort-title',
            },
        ],
    },
    {
        label: 'tCO₂e',
        position: 5,
        type: 'sort-tCO2e',
        field_type: 'single_option',
        options: [
            {
                name: useStoryblokTranslation('general', 'sort_asc'),
                value: 'content.tCO2e:asc:int',
                type: 'sort-tCO2e',
            },
            {
                name: useStoryblokTranslation('general', 'sort_desc'),
                value: 'content.tCO2e:desc:int',
                type: 'sort-tCO2e',
            },
        ],
    },
]));

const projectsData = ref([]);
const mappedDropdowns = ref([]);
const relations = ref([]);
const storyblokApi = useStoryblokApi();
const filterStore = useFilterStore();
const departmentFilter = computed(() => filterStore.getDepartments);
const cantonFilter = computed(() => filterStore.getCanton);
const projectTypeFilter = computed(() => filterStore.getTypeOfProject);
const nameSort = computed(() => filterStore.getSortByName);
const CO2Sort = computed(() => filterStore.getSortBytCO2e);

const pageSize = ref(10);
const page = ref(1);
const showLoadMore = ref(true);

const getDropdownData = (rels) => {
    mappedDropdowns.value = [];
    const filterQueries = [];

    filterParams.value.forEach((option) => {
        filterQueries.push({
            label: option.label,
            options: rels.filter((item) => item.content.component === option.storyblok_component)
                .map((rel) => ({
                    name: rel.name,
                    value: rel.uuid,
                    type: option.type,
                })),
            type: option.type,
            position: option.position,
            field_type: option.field_type,
        });
    });

    const result = filterQueries.concat(sortParams.value).sort((a, b) => (a.position - b.position));
    mappedDropdowns.value.push(...result);
};

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const getProjects = async () => {
    const department = departmentFilter.value.length === 0 ? {} : {
        in: departmentFilter.value.toString(),
    };

    const type = projectTypeFilter.value.length === 0 ? {} : {
        in: projectTypeFilter.value.toString(),
    };

    const canton = cantonFilter.value.length === 0 ? {} : {
        in: cantonFilter.value.toString(),
    };

    const sortQuery = [nameSort.value, CO2Sort.value].filter((item) => item.length > 0).join(',');

    const { data, headers } = await storyblokApi.get('cdn/stories/', {
        starts_with: 'data-sources/projects',
        version: 'published',
        filter_query: { department, type, canton },
        per_page: pageSize.value, // Maximum 6 * 16 = 96 and the limit is 100 https://www.storyblok.com/docs/technical-limits
        page: page.value,
        sort_by: sortQuery,
        resolve_relations: 'Project.department,Project.type,Project.canton',
        language,
    });

    if (page.value === 1) {
        projectsData.value = data.stories;
        if (projectsData.value.length < parseInt(headers?.total, 10)) {
            showLoadMore.value = true;
        } else {
            showLoadMore.value = false;
        }
        return;
    }

    projectsData.value.push(...data.stories);

    if (projectsData.value.length === parseInt(headers?.total, 10)) {
        showLoadMore.value = false;
    }
};

const getCantons = async () => {
    const { data } = await storyblokApi.get('cdn/stories/', {
        starts_with: 'data-sources/cantons',
        version: 'published',
        per_page: 100,
        language,
    });
    const dataProjects = await storyblokApi.get('cdn/stories/', {
        starts_with: 'data-sources/type-of-project',
        version: 'published',
        language,
    });
    relations.value.push(...data.stories.filter((item) => !item.content.selectAll));
    relations.value.push(...dataProjects.data.stories);
};

onMounted(async () => {
    await getCantons();
    if (props.department) {
        filterStore.setDepartments([props.department]);
    } else {
        filterParams.unshift(filterDepartmentParams);
    }
    await getProjects();

    if (relations.value) {
        await getDropdownData(relations.value);
    }
});

watch(filterStore, async () => {
    page.value = 1;
    await getProjects();
});

const loadMore = () => {
    page.value += 1;
    getProjects();
};

const colorTopLevel = inject('colorSchema');
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>
<style lang="scss" scoped>
.delete-padding {
    @include mobile {
        padding: 0 ;
    }
}

.storyblok-projects-block {
    @include fluid('padding-bottom', 30px, 66px);
    @include fluid('padding-top', 30px, 170px);
    @include fluid('margin-bottom', 30px, 66px);

    background-color: var(--c-white);
}

.storyblok-projects-block__title {
    padding: 26px  30px;
    margin-bottom: 3px;
    background-color: var(--c-background);
    color: v-bind(color4);
    font-family: var(--f-family--black);
    font-size: var(--f-size--h3);

    line-height: var(--l-height--h3);
    @include tablet {
        padding: 20px;
    }
}

.storyblok-projects-block__content {
    @include grid-columns(3, calc(var(--grid-columns) - 4));

    @include tablet {
        @include grid-columns(1, calc(var(--grid-columns) - 1));
    }

    @include mobile {
        @include grid-columns(1, var(--grid-columns));
    }
}

.storyblok-projects-block__filter {
    margin-bottom: 3px;
    background-color: var(--c-background);
}

.storyblok-projects-block__block {
    @include fluid('margin-bottom', 30px, 60px);
}

.storyblok-projects-block__more-button {
    @include mobile {
        margin-left: var(--page-padding);
    }
}
</style>
