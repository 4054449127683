<template>
  <div
    v-if="!isFiltered"
    class="block-teaser__wrapper"
  >
    <div
      v-for="article in pinnedItems"
      :key="article._uid"
      class="block-teaser__pinned-items"
    >
      <BlockItemTeaser
        v-if="article.article"
        :item="article"
      />
    </div>

    <div class="block-teaser">
      <BlockItemTeaser
        v-for="article in items"
        :key="article._uid"
        class="block-teaser__fancy-item"
        :item="article"
      />
    </div>

    <AtomButton
      v-if="showLoadMore"
      class="block-teaser__more-button"
      :text="useStoryblokTranslation('general', 'more_articles')"
      @click="loadMore()"
    />
  </div>
</template>

<script setup>
defineProps({
    pinnedItems: {
        type: Array,
        default: () => [],
        validator: (value) => typeof value === 'object',
    },

    items: {
        type: Array,
        default: () => [],
        validator: (value) => typeof value === 'object',
    },

    showLoadMore: {
        type: Boolean,
        default: true,
    },
});

const filterStore = useFilterStore();
const isFiltered = computed(() => filterStore.isFiltered);

const emit = defineEmits(['loadMore']);

const loadMore = () => {
    emit('loadMore', true);
};
</script>

<style lang="scss" scoped>
.block-teaser {
    @include grid-layout(12);
}

.block-teaser__more-button {
    max-width: 240px;
    align-self: end;
    margin-bottom: 50px;

    &.isHidden {
        display: none;
    }
}
</style>
