<template>
  <div class="storyblok-teaser-departments is-medium-wide">
    <UtilWrapperLayout>
      <UtilGrid>
        <div class="storyblok-teaser-departments__wrapper is-medium-wide">
          <div
            v-for="(item, index) in items"
            v-show="!isTablet"
            :key="item._uid"
            class="storyblok-teaser-departments__card"
            @mouseover="toggleState(true, index)"
            @focusin="toggleState(true, index)"
            @mouseleave="toggleState(false)"
            @focusout="toggleState(false)"
          >
            <AtomImage
              v-if="item?.image?.filename"
              class="storyblok-teaser-departments__image"
              :image="item?.image"
              width="450px"
              height="350px"
              provider="storyblok"
            />
            <div class="storyblok-teaser-departments__heading">
              <h4 v-if="item?.title" class="storyblok-teaser-departments__title">
                {{ item?.title }}
              </h4>
              <p v-if="item?.lead" class="storyblok-teaser-departments__lead card">
                {{ item?.lead }}
              </p>
            </div>

            <BlockItemCard
              v-if="hover && hoverCardId === index && items[hoverCardId]"
              :image="items[hoverCardId].image"
              :title="items[hoverCardId].title"
              :lead="items[hoverCardId].lead"
              :text="items[hoverCardId].text"
              :link="items[hoverCardId].link"
              :link-label="items[hoverCardId].linkLabel"
              :color="items[hoverCardId].color"
              :hover-id="hoverCardId + 1"
              class="storyblok-teaser-departments__popup-card"
            />
          </div>

          <div
            v-if="isTablet"
            class="storyblok-teaser-departments__mobile-cards"
          >
            <BlockItemCard
              v-for="item in items"
              :key="item._uid"
              :image="item.image"
              :title="item.title"
              :lead="item.lead"
              :text="item.text"
              :link="item.link"
              :link-label="item.linkLabel"
              :color="item.color"
              class="storyblok-teaser-departments__mobile-card"
            />
          </div>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const hover = ref(false);
const hoverCardId = ref(0);
const clientStore = useClientDeviceStore();
const isTablet = computed(() => (clientStore.isTablet));
const toggleState = (val, index) => {
    hover.value = val;
    hoverCardId.value = index;
};

defineProps({
    items: {
        type: Array,
        required: true,
        default: () => [],
        validator: (value) => value.length > 0,
    },
});

</script>

<style lang="scss" scoped>
.storyblok-teaser-departments {
    @include fluid('margin-bottom', 50px, 80px);

    position: relative;
    background: var(--c-white);
}

.storyblok-teaser-departments__wrapper {
    @include default-content-columns();
    @include fluid('margin-top', 20px, 90px);
    @include fluid('gap', 20px, 40px);

    display: flex;
}

.storyblok-teaser-departments__image {
    height: 320px;
}

.storyblok-teaser-departments__heading {
    padding: 40px 40px 67px 40px;
    color: var(--c-petrol-1);
}

.storyblok-teaser-departments__title {
    font-family: var(--f-family--black);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);
}

.storyblok-teaser-departments__lead {
    max-width: 345px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
}

.storyblok-teaser-departments__mobile-cards {
    @include fluid('margin-bottom', 70px, 80px);

    display: none;
    flex-wrap: wrap;
    gap: 20px;

    @include tablet {
        display: flex;
    }
}
</style>
