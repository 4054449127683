<template>
  <div class="storyblok-teaser-pdf">
    <UtilWrapperLayout>
      <UtilGrid>
        <div class="storyblok-teaser-pdf__section is-narrow">
          <div class="storyblok-teaser-pdf__header">
            <p class="storyblok-teaser-pdf__header__title">
              {{ title }}
            </p>
            <div class="storyblok-teaser-pdf__header__icons">
              <a
                class="storyblok-teaser-pdf__header__icon"
                :href="amazonBucketUrl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span class="storyblok-teaser-pdf__screen-readers-only">Zoom</span>
                <IonIcon icon-name="zoom" />
              </a>
              <button class="storyblok-teaser-pdf__header__icon" type="button" @click="printPdf(pdf.filename)">
                <IonIcon icon-name="print" />
              </button>
              <a
                :href="amazonBucketUrl"
                class="storyblok-teaser-pdf__header__icon"
                download
                target="_blank"
                rel="noopener noreferrer"
                title="Home"
              >
                <span class="storyblok-teaser-pdf__screen-readers-only">Download</span>
                <IonIcon icon-name="arrow-download" />
              </a>
            </div>
          </div>
          <AtomItemAccordion :title="subtitle" class="storyblok-teaser-pdf__accordion-content">
            <template #content>
              <ClientOnly>
                <BlockViewerPdf :url-pdf="amazonBucketUrl" />
              </ClientOnly>
            </template>
          </AtomItemAccordion>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>

const props = defineProps({
    title: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },
    subtitle: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },
    pdf: {
        type: Object,
        required: true,
    },
});
const amazonBucketUrl = computed(() => `https://s3.amazonaws.com/a.storyblok.com${props.pdf.filename.replace('https://a.storyblok.com', '')}`);
const printPdf = (file) => {
    printJS(file);
};

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
.storyblok-teaser-pdf__section {
    @include default-content-columns;
    @include fluid('margin-bottom', 30px, 50px);
}
.storyblok-teaser-pdf__header,
.storyblok-teaser-pdf__accordion__header {
    display: flex;
    justify-content: space-between;
    padding: 22px 32px 27px 27px;
    background: var(--c-white);
}
.storyblok-teaser-pdf__header {
    margin-bottom: 3px;
}
.storyblok-teaser-pdf__header__title {
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);
}
.storyblok-teaser-pdf__header__icons {
    display: flex;
    gap: 30px;
}
.storyblok-teaser-pdf__header__icon {
    display: flex;
    align-items: center;
}

.storyblok-teaser-pdf__screen-readers-only {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0,0,0,0);
}
.storyblok-teaser-pdf__accordion__header {
    width: 100%;
}
.storyblok-teaser-pdf__accordion-content {
    color: v-bind(color1);
}

</style>
