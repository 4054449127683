<template>
  <div class="storyblok-teaser-projects">
    <UtilWrapperLayout>
      <UtilGrid>
        <div class="storyblok-teaser-projects__wrapper is-medium-wide">
          <div class="storyblok-teaser-projects__heading">
            <h4 class="storyblok-teaser-projects__title">
              {{ title }}
            </h4>
          </div>

          <div v-if="!isTablet" class="storyblok-teaser-projects__cards">
            <BlockItemProject
              v-for="item in items"
              :key="item._uid"
              v-bind="{ ...item }"
            />
          </div>
          <Swiper
            v-if="isTablet"
            :modules="modules"
            slides-per-view="auto"
            :space-between="20"
            :grab-cursor="true"
            class="storyblok-teaser-projects__swiper"
            @swiper="onSwiper"
          >
            <SwiperSlide
              v-for="item in items"
              :key="item._uid"
              class="storyblok-teaser-projects__swiper-card"
            >
              <BlockItemProject
                v-bind="{ ...item }"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const { Swiper, SwiperSlide } = useSwiperjs();
defineProps({
    title: {
        type: String,
        default: '',
    },
    items: {
        type: Array,
        default: () => [],
    },
});
/*
    Init Swiper
*/
const swiperController = ref(null);
const clientDeviceStore = useClientDeviceStore();
const isTablet = computed(() => clientDeviceStore.isTablet);

const onSwiper = (e) => {
    swiperController.value = e;
};

</script>

<style lang="scss">
.storyblok-teaser-projects {
    @include fluid('margin-bottom', 30px, 50px);
}

.storyblok-teaser-projects__wrapper {
    @include default-content-columns;

    overflow: hidden;
}

.storyblok-teaser-projects__heading {
    max-width: 764px;
    margin: 0 auto;
}

.storyblok-teaser-projects__title {
    @include fluid('padding-bottom', 20px, 30px);

    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);
}

.storyblok-teaser-projects__cards {
    @include grid-layout(3);

    row-gap: var(--grid-gutter);
}

.storyblok-teaser-projects__swiper {
    display: none;

    @include tablet {
        display: flex;
        width: 100%;
        flex-direction: row;

        .storyblok-teaser-projects__swiper-card {
            min-width: 270px;
        }

        .block-item-teaser {
            width: 270px !important;
            margin-bottom: 10px;

        }

        .swiper-slide {
            width: 270px !important;
            @include mobile {
                width: 290px !important;
            }
        }
    }
}
  </style>
