<template>
  <div class="block-image-gallery">
    <div
      :class="`block-image-gallery__wrapper block-image-gallery__wrapper--${
        previewImages.slice(0, 4).length
      }`"
    >
      <div
        :class="`block-image-gallery__gallery block-image-gallery__gallery--${
          previewImages.slice(0, 4).length
        }`"
      >
        <template v-for="item in previewImages.slice(0, 4)" :key="item._uid">
          <AtomImage
            v-if="item?.filename"
            :image="item"
            class="atom-teaser-card__image"
            :provider="item.provider"
          />
        </template>
      </div>
      <div
        v-if="items.length > 1"
        class="block-image-gallery__open"
        @click="sliderImageStore.openSliderImage()"
        @keypress="sliderImageStore.openSliderImage()"
      >
        <div class="block-image-gallery__open-label">
          {{ useStoryblokTranslation('general', 'to_gallery') }}
        </div>
        <IonIcon icon-name="chevron-right" class="block-image-gallery__button-icon" />
      </div>
    </div>
    <transition name="fade-in">
      <BlockSliderImage v-if="isOpen" :slides="items" />
    </transition>
  </div>
</template>

<script setup>
defineProps({
    previewImages: {
        type: Array,
        default: () => [],
        validator: (value) => value.length > 0,
    },
    items: {
        type: Array,
        default: () => [],
        validator: (value) => value.length > 0,
    },
});

const sliderImageStore = useSliderImageStore();
const isOpen = ref(false);

watch(
    () => sliderImageStore.isOpen,
    (value) => {
        isOpen.value = value;
    },
);

</script>

<style lang="scss" scoped>
/* stylelint-disable no-descending-specificity */
.block-image-gallery {
    @include default-content-columns;
}

.block-image-gallery__gallery {
    height: 100%;
}

.block-image-gallery__gallery--1 {
    display: grid;
    height: 344px;
    grid-gap: 3px;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(9, 1fr);

    .atom-teaser-card__image {
        grid-column-end: 9;
        grid-column-start: 1;
        grid-row-end: 10;
        grid-row-start: 1;

        img {
            object-fit: cover;
        }
    }

    @include mobile {
        max-width: 100%;
        height: 250px;
    }
}

.block-image-gallery__gallery--2 {
    display: grid;
    height: 344px;
    grid-gap: 3px;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(9, 1fr);

    .atom-teaser-card__image {
        &:first-of-type {
            grid-column-end: 6;
            grid-column-start: 1;
            grid-row-end: 10;
            grid-row-start: 1;

            img {
                object-fit: cover;
            }
        }

        &:nth-of-type(2) {
            grid-column-end: 9;
            grid-column-start: 6;
            grid-row-end: 10;
            grid-row-start: 1;

            img {
                object-fit: cover;
            }
        }
    }

    @include mobile {
        max-width: 100%;
        height: 250px;
    }
}

.block-image-gallery__gallery--3 {
    display: grid;
    height: 433px;
    grid-gap: 3px;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);

    @include above-tablet {
        grid-gap: 5px;
    }

    @include mobile {
        max-width: 100%;
        height: 250px;
    }

    .atom-teaser-card__image {
        &:first-of-type {
            grid-column-end: 9;
            grid-column-start: 7;
            grid-row-end: 4;
            grid-row-start: 1;

            img {
                object-fit: cover;
            }
        }

        &:nth-of-type(2) {
            grid-column-end: 7;
            grid-column-start: 1;
            grid-row-end: 9;
            grid-row-start: 1;

            img {
                object-fit: cover;
            }
        }

        &:last-of-type {
            grid-column-end: 9;
            grid-column-start: 7;
            grid-row-end: 9;
            grid-row-start: 4;
        }
    }
}

.block-image-gallery__gallery--4 {
    display: grid;
    width: 100%;
    max-height: 500px;
    grid-gap: 3px;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);

    @include above-tablet {
        grid-gap: 5px;
    }

    @include mobile {
        max-width: 100%;
        height: 250px;
    }

    .atom-teaser-card__image {
        img {
            object-fit: cover;
        }

        &:first-of-type {
            grid-column-end: 4;
            grid-column-start: 1;
            grid-row-end: 9;
            grid-row-start: 1;
        }
        &:nth-of-type(2) {
            grid-column-end: 9;
            grid-column-start: 4;
            grid-row-end: 6;
            grid-row-start: 1;
        }
        &:nth-of-type(3) {
            grid-column-end: 9;
            grid-column-start: 7;
            grid-row-end: 9;
            grid-row-start: 6;
        }
        &:last-of-type {
            grid-column-end: 4;
            grid-column-start: 7;
            grid-row-end: 9;
            grid-row-start: 6;
        }
    }
}

.block-image-gallery__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    &--1,
    &--2 {
        grid-column: 5 / span 8;

        @include tablet-portrait {
            grid-column: 2 / span 8;
        }

        @include mobile {
            grid-column: 1 / span 8;
        }
    }

    &--3,
    &--4 {
        grid-column: 5 / span 13;

        @include tablet-portrait {
            grid-column: 2 / span 13;
        }

        @include mobile {
            grid-column: 1 / span 8;
        }
    }

    @include mobile {
        margin-bottom: 10px;
    }
}
.block-image-gallery__open {
    margin-top: 30px;
    color: var(--c-primary);
    cursor: pointer;
    font-family: var(--f-family--black);

    @include above-tablet {
        padding: 0;
    }

    @include mobile {
        padding: 25px;
        margin-top: 0px;
    }

    div,
    .ion-icon {
        color: var(--c-primary);
        transition: transform 0.2s ease-in-out;
    }

    &:hover {
        .ion-icon {
            transform: translateX(10px);
        }
    }
}

.block-image-gallery__open-label {
    margin-bottom: 10px;
}
</style>
