<template>
  <div
    class="block-custom-code"
    v-html="code ? code : data"
  />
</template>

<script setup>
defineProps({
    code: {
        type: String,
        required: true,
    },
});

// inject the legaly ok content
const data = inject('legallyOkContent');
</script>

<style lang="scss">
.block-custom-code {
    display: flex;
    flex-direction: column;
    color: var(--c-petrol-1);

    ul {
        margin: 20px 0;
        font-family: var(--f-family--thin);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);

        li {
            margin-bottom: 20px;
            line-height: 130%;
        }
    }

    .jmdsnippet {
        min-height: unset !important;
        color: var(--c-petrol-1);
        font-family: var(--f-family--regular);
    }

    #jmdcontent {
        margin-bottom: 20px;
    }

    h1 {
        font-family: var(--f-family--bold);
        font-size: var(--f-size--h1);
        line-height: var(--l-height--h1);
    }

    h2 {
        font-family: var(--f-family--bold);
        font-size: var(--f-size--h2);
        line-height: var(--l-height--h2);
    }

    h3 {
        font-family: var(--f-family--bold);
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);
    }

    h4 {
        font-family: var(--f-family--bold);
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);
    }

    p {
        font-family: var(--f-family--thin);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    table {
        width: 100%;
        margin: 2em 0;
        border-collapse: collapse;
        border-spacing: 0;

        caption {
            font-family: var(--f-family--bold);
        }

        tbody {
            border-top: 1px solid var(--c-black);
        }

        tr {
            border-bottom: 1px solid var(--c-black);
        }

        th {
            font-family: var(--f-family--bold);
        }

        td {
            font-family: var(--f-family--regular);
        }

        caption,
        th,
        td {
            padding: 1em 0;
            vertical-align: top;
        }

        th,
        td {
            &:not(:last-child) {
                padding-right: 1em;
            }
        }
    }

}
</style>
