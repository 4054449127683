<template>
  <div class="storyblok-activities-filtered">
    <UtilWrapperLayout>
      <UtilGrid>
        <BlockFilter
          class="storyblok-activities-filtered__filter is-medium-wide"
          :title="useStoryblokTranslation('general', 'filter_by')"
          :dropdowns="sortFilterDropdowns(mappedDropdowns)"
          :color="color"
        />
        <BlockFilteredActivities
          class="storyblok-activities-filtered__block is-narrow"
          :articles="activities"
          :color="color"
          :button-label="buttonLabel"
        />
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>

const props = defineProps({
    filterByCountry: {
        type: String,
        default: '',
    },

    buttonLabel: {
        type: String,
        default: '',
    },
});

const filterParamsData = [{
    label: useStoryblokTranslation('general', 'country'),
    slug: 'international/',
    content_type: 'Country',
    position: 1,
    type: 'countries',
    storyblok_component: 'Country',
}, {
    label: useStoryblokTranslation('general', 'activities'),
    slug: 'data-sources/activities',
    content_type: '',
    position: 2,
    type: 'type-of-activity',
    storyblok_component: 'Program',
}];

const filterParams = computed(() => (props.filterByCountry
    ? filterParamsData.slice(1) : filterParamsData));

const activities = ref([]);
const mappedDropdowns = ref([]);
const relations = ref({});

const storyblokApi = useStoryblokApi();
const filterStore = useFilterStore();
const colorStore = useColorStore();
const color = computed(() => colorStore.getGlobalColor);

const countries = computed(() => filterStore.getCountries);
const typesOfActivity = computed(() => filterStore.getTypesOfActivity);
const activityStore = useActivityStore();
const savedIndex = computed(() => activityStore.getStoredIndex);

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const getDropdownData = (rels) => {
    mappedDropdowns.value = [];
    filterParams.value.forEach((option) => {
        mappedDropdowns.value.push({
            label: option.label,
            options: rels.filter((item) => item.content.component === option.storyblok_component)
                .map((rel) => ({
                    name: rel.translated_slugs?.find(
                        (slug) => slug.lang === currentLocale,
                    )?.name || rel.name,
                    value: rel.uuid,
                    type: option.type,
                })),
            type: option.type,
            position: option.position,
        });
    });
};

const sortFilterDropdowns = (data) => {
    const sorted = data.sort((a, b) => (a.position < b.position));
    return sorted;
};

const getArticles = async () => {
    const country = countries.value.length === 0 ? {} : {
        in: countries.value.toString(),
    };

    const type = typesOfActivity.value.length === 0 ? {} : {
        any_in_array: typesOfActivity.value.toString(),
    };

    const { data } = await storyblokApi.get('cdn/stories/', {
        starts_with: 'international/activities/',
        version: 'published',
        per_page: 100,
        is_startpage: false,
        filter_query: { country, type },
        sort_by: 'first_published_at:desc',
        resolve_relations: 'Activity.type,Activity.country',
        language,
    });

    activities.value = data.stories;

    if (
        typeof country === 'object'
        && typeof type === 'object'
        && Object.keys(country).length === 0
        && Object.keys(type).length === 0
    ) {
        // random shuffle activities if no filter is set and no index is saved
        if (!savedIndex.value) activities.value.sort(() => Math.random() - 0.5);
    }

    relations.value = data.rels;
};

onMounted(async () => {
    if (props.filterByCountry) {
        filterStore.setCountries(props.filterByCountry);
    }

    filterStore.resetData();

    await getArticles();
    getDropdownData(relations.value);
});

watch(filterStore, () => {
    getArticles();
});

</script>

<style lang="scss" scoped>
.storyblok-activities-filtered {
    @include fluid('margin-bottom', 30px, 50px);
}

.storyblok-activities-filtered__filter,
.storyblok-activities-filtered__block {
    @include default-content-columns;
}
</style>
