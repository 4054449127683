<template>
  <div class="atom-item-download-card-mini">
    <div class="block-download-hub__section-content-wrapper-left">
      <div class="block-download-hub__section-content">
        <p class="atom-item-download-card-mini__date">
          {{ dayjs(date)?.format('DD/MM/YYYY') }}
        </p>
        <p class="atom-item-download-card-mini__heading">
          <span class="atom-item-download-card-mini__heading-title">{{ title }}</span>
        </p>
        <p class="atom-item-download-card-mini__text">
          {{ text }}
        </p>
      </div>
      <span v-if="fileData?.extention" class="atom-item-download-card-mini__tag">{{ fileData?.extention }}</span>
    </div>

    <div class="block-download-hub__section-btn">
      <div class="block-download-hub__slot">
        <slot name="select" />
      </div>
      <AtomButton
        :text="useStoryblokTranslation('general', 'preview')"
        class="block-download-hub__btn"
        :color-code="department.content?.color_4"
        :color-code-hover="department.content?.color_3"
        @click="downloadItem(fileData?.url, fileData?.extention)"
      />
    </div>

    <div class="block-download-hub__wrapper-mobile">
      <span v-if="fileData?.extention" class="atom-item-download-card-mini__tag --mobile">{{ fileData?.extention }}</span>
      <slot class="block-download-hub__slot --mobile" name="select" />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    date: {
        type: String,
        default: '',
    },

    fileData: {
        type: Object,
        default: () => ({}),
    },

    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    department: {
        type: Object,
        default: () => ({}),
    },
});

const colorText = computed(() => props.department.content.color_1);
const colorText2 = computed(() => props.department?.content?.color_6);
const colorBg = computed(() => props.department.content.color_11);
const { dayjs } = useDayJs();

const downloadItem = async (filename) => {
    const isSafari = navigator?.vendor.includes('Apple');

    if (props.fileData?.extention === 'pdf' && !isSafari) {
        window.open(filename);
    } else if (props.fileData?.extention === 'pdf' && isSafari) {
        const newWindow = window.open();
        newWindow.location.href = filename;
    } else {
        const a = document.createElement('a');
        a.href = filename;
        a.download = filename.split('/').pop();
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
};
</script>

  <style lang="scss" scoped>
    .atom-item-download-card-mini {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 30px 27px;
        background: var( --c-white);
        color: v-bind(colorText);
        column-gap: 5px;

        @include mobile {
            flex-direction: column;
            padding: 10px;
            row-gap: 20px;
        }
    }

    .block-download-hub__section-content {
        @include mobile {
            display: flex;
            flex-direction: column;
            padding-top: 5px;
            row-gap: 2px;
        }
    }

    .block-download-hub__section-content-wrapper-left {
        display: flex;
        align-items: flex-end;
        column-gap: 15px;
    }

    .atom-item-download-card-mini__heading {
        font-family: var(--f-family--black);
        font-size: 24px;
        gap: 10px;
        line-height: 38px;
    }

    .atom-item-download-card-mini__text {
        display: none;
        padding-bottom: 30px;

        @include mobile {
            padding-bottom: 0px;
        }
    }

    .atom-item-download-card-mini__date {
        @include line-clamp(4);

        font-family: var(--f-family--thin);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    .atom-item-download-card-mini__select {
        margin-bottom: 10px;
    }

    .atom-item-download-card-mini__heading-title {
        @include word-break;

        display: block;
        font-family: var(--f-family--bold);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    .atom-item-download-card-mini__tag {
        padding: 6px 12px;
        border-radius: 4px;
        background: v-bind(colorBg);
        color: v-bind(colorText2);
        font-family: var(--f-family--thin);
        font-size: 14px;
        vertical-align: middle;

        @include mobile {
            display: none;

            &.--mobile {
                display: block;
            }
        }
    }

    .block-download-hub__section-btn {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }

    .block-download-hub__wrapper-mobile {
        display: none;

        @include mobile {
            position: absolute;
            top: 10px;
            right: 10px;
            display: flex;
            height: 28px;
            justify-content: space-between;
            column-gap: 5px;
        }

    }

    .block-download-hub__slot {
        &.--mobile {
            display: flex;
        }

        @include mobile {
            display: none;
        }
    }

    .atom-item-download-card-mini__tag-section {
        align-self: flex-start;
    }
  </style>
