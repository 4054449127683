<template>
  <div class="atom-item-download-card">
    <div class="block-download-hub__section-content">
      <div class="atom-item-download-card__date">
        {{ dayjs(date)?.format('DD/MM/YYYY') }}
        <div class="atom-item-download-card__tag-wrapper">
          <div v-if="fileData?.extention" class="atom-item-download-card__tag">
            {{ fileData?.extention }}
          </div>
          <slot name="select" />
        </div>
      </div>
      <p class="atom-item-download-card__heading">
        <span class="atom-item-download-card__heading-title">{{ title }}</span>
      </p>
      <p class="atom-item-download-card__text">
        {{ text }}
      </p>
    </div>

    <div class="block-download-hub__section-btn">
      <AtomButton
        :text="fileData?.extention === 'pdf'
          ? useStoryblokTranslation('general', 'preview')
          : useStoryblokTranslation('general', 'download')"
        class="block-download-hub__btn"
        :color-code="department.content?.color_4"
        :color-code-hover="department.content?.color_3"
        @click="downloadItem(fileData?.url, fileData?.extention)"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    date: {
        type: String,
        default: '',
    },

    fileData: {
        type: Object,
        default: () => ({}),
    },

    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    department: {
        type: Object,
        default: () => ({}),
    },
});

const colorText = computed(() => props.department?.content?.color_1);
const colorText2 = computed(() => props.department?.content?.color_6);
const colorBg = computed(() => props.department?.content?.color_11);
const { dayjs } = useDayJs();

const downloadItem = async (filename) => {
    const isSafari = navigator?.vendor.includes('Apple');

    if (props.fileData?.extention === 'pdf' && !isSafari) {
        window.open(filename);
    } else if (props.fileData?.extention === 'pdf' && isSafari) {
        const newWindow = window.open();
        newWindow.location.href = filename;
    } else {
        const a = document.createElement('a');
        a.href = filename;
        a.download = filename.split('/').pop();
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
};
</script>

<style lang="scss" scoped>
.atom-item-download-card {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    background: var( --c-white);
    color: v-bind(colorText);

    @include mobile {
        padding: 30px;
    }
}

.atom-item-download-card__heading {
    padding-bottom: 20px;
    font-family: var(--f-family--black);
    font-size: var(--f-size--h4);
    gap: 10px;
    line-height: var(--l-height--h4);
}

.atom-item-download-card__text {
    padding-bottom: 30px;
}

.atom-item-download-card__date,
.atom-item-download-card__text {
    @include line-clamp(4);

    display: flex;
    overflow: visible;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--description);
    line-height: var(--l-height--description);
}

.atom-item-download-card__tag-wrapper {
    display: flex;
    height: 30px;
    justify-content: flex-end;
    column-gap: 5px;
    row-gap: 4px;
}

.atom-item-download-card__select {
    margin-bottom: 10px;
}

.block-download-hub__btn {
    align-self: flex-start;
}

.atom-item-download-card__heading-title {
    @include word-break;

    display: block;
}

.atom-item-download-card__tag {
    display: flex;
    width: auto;
    align-items: center;
    padding: 0 12px;
    border-radius: 4px;
    background: v-bind(colorBg);
    color: v-bind(colorText2);
    font-family: var(--f-family--thin);
    font-size: 14px;
}

.atom-item-download-card__tag-section {
    align-self: flex-start;
}
</style>
