<template>
  <div class="block-download-hub-small">
    <div class="block-download-hub-small__search">
      <div
        class="block-download-hub-small__search-title"
        :class="{ 'is-active': searchFilterOpen }"
        @click="toggleOpen('search')"
        @keypress="toggleOpen('search')"
      >
        <div class="block-download-hub-small__search-title-wrapper">
          <p>{{ useStoryblokTranslation('general', 'search_term') }}</p>
          <IonIcon
            class="block-download-hub-small__search-title-icon-magnifying"
            icon-name="search"
          />
        </div>
        <IonIcon
          class="block-download-hub-small__search-title-icon"
          icon-name="chevron-down"
        />
      </div>
      <div
        class="block-download-hub-small__search-content-wrapper"
        :class="{ 'is-open': searchFilterOpen }"
      >
        <AtomInputText
          class="block-download-hub-small__search-input"
          name="search"
          label="Search"
          @set-input="filterText"
        />
        <IonIcon
          class="block-download-hub-small__search-title-icon-magnifying"
          icon-name="search"
        />
      </div>
    </div>

    <div class="block-download-hub-small__filter-secondary">
      <p>
        {{ useStoryblokTranslation('general', 'filter') }}
      </p>
      <div
        v-if="isArrayNotEmpty(activeSecondryFilter)"
        class="block-download-hub-small__filter-secondary-selectfields-wrapper"
      >
        <div class="block-download-hub-small__filter-secondary-selectfields">
          <AtomSingleselect
            v-for="filter in activeSecondryFilter"
            :key="filter.title"
            :options="filter.options"
            :label="filter.label"
            :name="filter.name"
            class="block-filter__select"
            @set-data="setSecondryFilter"
          />
        </div>
      </div>
    </div>

    <div
      :key="rerenderBlock"
      class="block-download-hub-small__filtered-files"
    >
      <div
        v-for="(file, index) in downloadFiles"
        :key="file._uid"
        class="block-download-hub-small__file desktop"
      >
        <AtomItemDownloadCard
          v-bind="{ ...file?.content }"
          :date="file?.first_published_at"
          :file-data="file?.fileData"
        >
          <template #select>
            <AtomSelect
              v-if="isArrayNotEmpty(file.content?.files)"
              :options="convertLangDataOptions(file.content?.files)"
              :card-index="index"
              class="block-download-hub-small__select"
              :color-bg="file.content?.department?.content?.color_11"
              :color-text="file.content?.department?.content?.color_1"
              :color-icon="file.content?.department?.content?.color_4"
              :has-initial-emit="true"
              @set-data="getLangData"
            />
          </template>
        </AtomItemDownloadCard>
      </div>

      <div
        v-if="isArrayNotEmpty(downloadFiles)"
        class="block-download-hub-small__swiper-wrapper"
      >
        <swiper
          :direction="'horizontal'"
          :grab-cursor="true"
          :space-between="3"
        >
          <swiper-slide
            v-for="(file, index) in downloadFiles"
            :key="file._uid"
            class="block-download-hub-small__file"
          >
            <AtomItemDownloadCard
              v-bind="{ ...file?.content }"
              :date="file?.first_published_at"
              :file-data="file?.fileData"
            >
              <template #select>
                <AtomSelect
                  v-if="isArrayNotEmpty(file.content?.files)"
                  :options="convertLangDataOptions(file.content?.files)"
                  :card-index="index"
                  class="block-download-hub-small__select"
                  :color-bg="file.content?.department?.content?.color_11"
                  :color-text="file.content?.department?.content?.color_1"
                  :color-icon="file.content?.department?.content?.color_4"
                  @set-data="getLangData"
                />
              </template>
            </AtomItemDownloadCard>
            <div
              v-if="isArrayEmpty(downloadFiles)"
              class="block-download-hub-small__no-result"
            >
              <p>{{ useStoryblokTranslation('general', 'no_results') }}</p>
              <AtomRichtext :html="noResults" />
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div
        v-if="isArrayEmpty(downloadFiles)"
        class="block-download-hub-small__no-result"
      >
        <p>{{ useStoryblokTranslation('general', 'no_results') }}</p>
        <AtomRichtext :html="noResults" />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    filterSecondary: {
        type: Array,
        default: () => [],
    },

    defaultState: {
        type: String,
        default: '',
    },
});

// import Swiper core and required modules
const {
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const {
    defaultState,
    filterSecondary,
} = toRefs(props);

const downloadFiles = ref(null);
const storyblokApi = useStoryblokApi();
const filterData = ref(null);
const noResults = storyblokApi?.richTextResolver.render(useStoryblokTranslation('general', 'no_results', true));
const mainFilterOpen = ref(defaultState.value === 'open_main');
const searchFilterOpen = ref(defaultState.value === 'open_search');
const sortData = ref(null);

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const rerenderBlock = ref(0);
const forceRerender = () => {
    rerenderBlock.value += 1;
};

const getLangData = (data) => {
    if (!data.url) {
        return;
    }

    downloadFiles.value[data.index].fileData = {
        url: data.url,
        extention: getFileExtension(data.url),
    };
};

const getDownloadFiles = async () => {
    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'download-hub',
            version: 'published',
            resolve_relations: 'Download File.department,Download File.country,Download File.program',
            filter_query: filterData.value,
            sort_by: sortData.value,
            language,
        });

        downloadFiles.value = data.stories;

        forceRerender();
    } catch (e) {
        useSentryError(e);
    }
};

const activeSecondryFilter = ref(null);
const sortFirstPublished = ref(null);
const sortAlphabetical = ref(null);

const setSecondryFilter = (data) => {
    if (data.name === 'date') {
        // sort by published date
        sortFirstPublished.value = `first_published_at:${data.value[0]}`;
    }

    if (data.name === 'sort') {
        // sort by title
        if (data.value[0]) {
            sortAlphabetical.value = `content.title:${data.value[0]}`;
        } else {
            sortAlphabetical.value = null;
        }
    }

    if (Array.isArray(data?.value) && data.name === 'thema' && data.value.length > 0) {
        filterData.value = {
            ...filterData.value,
            themenbereich: {
                in: data.value[0],
            },
        };
    } else {
        filterData.value = {
            ...filterData.value,
            themenbereich: null,
        };
    }

    // handling sort data
    if (sortFirstPublished.value && sortAlphabetical.value) {
        sortData.value = `${sortFirstPublished.value},${sortAlphabetical.value}`;
    } else if (sortFirstPublished.value) {
        sortData.value = sortFirstPublished.value;
    } else if (sortAlphabetical.value) {
        sortData.value = sortAlphabetical.value;
    } else {
        sortData.value = null;
    }

    if (data.name === 'format') {
        if (isArrayNotEmpty(data.value)) {
            // filter out the file type
            filterData.value = {
                ...filterData.value,
                format: {
                    like: `*${data.value[0]}`,
                },
            };
        } else {
            filterData.value = {
                ...filterData.value,
                format: null,
            };
        }
    }

    getDownloadFiles();
};

const convertLangDataOptions = (data) => {
    const options = [];

    data.forEach((item) => {
        options.push({
            language: item.language,
            value: item.file?.filename,
        });
    });

    return options;
};

const { throttledExecute } = useThrottledExecute(700);
const filterText = (data) => {
    const suffix = currentLocale.value === defaultLocale ? '' : `__i18n__${currentLocale.value}`;

    filterData.value = {
        [`title${suffix}`]: {
            like: `*${data}*`,
        },
    };

    if (data.length > 2) {
        throttledExecute(() => {
            getDownloadFiles();
        });
    }

    if (!data) {
        getDownloadFiles();
    }
};

const setFilter = async () => {
    activeSecondryFilter.value = props.filterSecondary;

    getDownloadFiles();
};

const toggleOpen = (type) => {
    if (type === 'main') {
        mainFilterOpen.value = !mainFilterOpen.value;
        searchFilterOpen.value = false;
    } else if (type === 'search') {
        searchFilterOpen.value = !searchFilterOpen.value;
        mainFilterOpen.value = false;
    }
};

onMounted(() => {
    getDownloadFiles();
    setFilter();
});

watch(() => filterSecondary.value, () => {
    setFilter();
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
.block-download-hub-small {
    display: flex;
    flex-direction: column;
    row-gap: 3px;

    .ion-icon {
        transition: transform 0.3s $EASE_IN_OUT--BACK;
    }

    .swiper {
        display: none;
        overflow: visible;

        @include mobile {
            display: block;
            width: calc(100% - var(--page-padding));
            margin-left: 0;
        }
    }
}

.block-download-hub-small__search {
    display: flex;
    width: 100%;
    flex-direction: column;
    background: var(--c-white);
}

.block-download-hub-small__search-title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 21px 30px;
    column-gap: 20px;
    cursor: pointer;

    p {
        color: v-bind(color1);
        font-family: var(--f-family--regular);
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);

        @include mobile {
            font-size: var(--f-size--description);
            line-height: var(--l-height--description);
        }
    }

    &.is-active {
        padding: 40px 30px;

        p{
            font-family: var(--f-family--black);
        }

        .block-download-hub-small__search-title-icon {
            transform: rotate(180deg);
        }

        .block-download-hub-small__search-title-icon-magnifying {
            display: none;
        }

        @include mobile {
            padding: 10px;
        }
    }

    @include mobile {
        padding: 10px;
    }
}

.block-download-hub-small__search-title-wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.block-download-hub-small__search-content-wrapper {
    display: flex;
    overflow: hidden;
    height: 0;
    align-items: center;
    column-gap: 20px;

    &.is-open {
        height: 100%;
        padding: 0 30px 40px 30px;

        @include mobile {
            padding: 0 10px 20px 10px;
        }
    }
}

.block-download-hub-small__search-input {
    width: 444px;

    @include mobile {
        width: 100%;
    }
}

.block-download-hub-small__filter-secondary {
    @include z-index('filter');

    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 21px 29px;
    background: var(--c-white);

    /* stylelint-disable-next-line no-descending-specificity */
    p {
        color: v-bind(color1);
        font-family: var(--f-family--regular);
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);
    }

    .block-filter__select {
        margin-left: 0;
    }

    @include mobile {
        flex-direction: column;
        padding: 10px;
        row-gap: 10px;
    }
}

.block-download-hub-small__filter-secondary-selectfields-wrapper {
    display: flex;
    align-items: center;
    gap: 30px;
}

.block-download-hub-small__filter-secondary-selectfields {
    display: flex;
    align-items: center;
    column-gap: 5px;
    row-gap: 5px;

    @include mobile {
        flex-wrap: wrap;
    }
}

.block-download-hub-small__filter-icon {
    cursor: pointer;

    &.is-active {
        .ion-icon {
            transform: rotate(180deg);
        }
    }

    @include mobile {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.block-download-hub-small__filtered-files {
    display: grid;
    background: var(--c-background);
    column-gap: 3px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 3px;

    @include mobile {
        display: block;
    }
}

.block-download-hub-small__file {
    display: flex;
    flex-direction: column;

    &.desktop {
        @include mobile {
            display: none;
        }
    }
}

.block-download-hub-small__no-result {
    padding: 40px 120px;
    grid-column: 1 / -1;

    /* stylelint-disable-next-line no-descending-specificity */
    p {
        margin-bottom: 20px;
        font-family: var(--f-family--thin);
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);
    }

    @include mobile {
        padding: 30px;
    }
}

.block-download-hub-small__content-wrapper {
    overflow: hidden;
    height: 0;

    &.is-open {
        height: 100%;
        padding: 0 30px 40px 30px;

        @include mobile {
            overflow: visible;
            padding: 0;
        }
    }

}

.block-download-hub-small__swiper-wrapper {
    overflow: hidden;
    width: calc(100% + var(--page-padding));
}
</style>
