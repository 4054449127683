<template>
  <div class="storyblok-employees-block">
    <UtilWrapperLayout class="delete-padding">
      <UtilGrid>
        <div class="storyblok-employees-block__filter-section is-medium-wide">
          <AtomHeading
            v-if="title"
            class="storyblok-employees-block__title"
            :text="title"
          />
          <BlockFilter
            v-if="relations.length > 0"
            class="storyblok-employees-block__filter"
            :title="useStoryblokTranslation('general', 'filter')"
            :dropdowns="sortFilterDropdowns(mappedDropdowns)"
            :color="color"
          />
          <div v-if="relations.length === 0" class="storyblok-employees-block__empty-filter" />
        </div>

        <BlockFilteredEmployees
          class="storyblok-employees-block__block is-narrow"
          :people="peopleData"
        />
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>
<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    people: {
        type: Array,
        default: () => [],
    },
});

const filterParams = [{
    label: useStoryblokTranslation('general', 'division'),
    content_type: 'Division',
    position: 1,
    type: 'division',
    storyblok_component: 'Division',
}, {
    label: useStoryblokTranslation('general', 'platform'),
    content_type: 'Department',
    position: 2,
    type: 'department',
    storyblok_component: 'Department',
}];

const peopleData = ref([]);
const mappedDropdowns = ref([]);
const relations = ref({});
const storyblokApi = useStoryblokApi();
const filterStore = useFilterStore();
const colorStore = useColorStore();

const color = computed(() => colorStore.getGlobalColor);
const departmentsFilter = computed(() => filterStore.getDepartments);
const divisionFilter = computed(() => filterStore.getDivision);

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const getDropdownData = (rels) => {
    mappedDropdowns.value = [];
    filterParams.forEach((option) => {
        mappedDropdowns.value.push({
            label: option.label,
            options: rels.filter((item) => item.content.component === option.storyblok_component)
                .map((rel) => ({
                    name: rel.name,
                    value: rel.uuid,
                    type: option.type,
                })),
            type: option.type,
            position: option.position,
        });
    });
    mappedDropdowns.value = mappedDropdowns.value.filter((item) => item.options.length > 0);
};
const sortFilterDropdowns = (data) => {
    const sorted = data.sort((a, b) => (a.position < b.position));
    return sorted;
};
const getPeople = async () => {
    const departments = departmentsFilter.value.length === 0 ? {} : {
        any_in_array: departmentsFilter.value.toString(),
    };

    const division = divisionFilter.value.length === 0 ? {} : {
        in: divisionFilter.value.toString(),
    };

    const { data } = await storyblokApi.get('cdn/stories/', {
        starts_with: 'data-sources/people',
        version: 'published',
        filter_query: { departments, division },
        per_page: 100,
        by_uuids: props.people.toString(),
        resolve_relations: 'Person.departments,Person.division',
        language,
    });

    peopleData.value = data.stories;
    relations.value = data.rels;
};

onMounted(async () => {
    await getPeople();
    if (relations.value.length > 0) {
        getDropdownData(relations.value);
    }
});

watch(filterStore, async () => {
    if (relations.value.length > 0) {
        await getPeople();
    }
});

</script>
<style lang="scss" scoped>
.delete-padding {
    @include mobile {
        padding: 0 ;
    }
}
.storyblok-employees-block__title {
    padding-bottom: 32px;
    @include mobile {
        padding-bottom: 10px;
        padding-left: var(--page-padding);
    }
}
.storyblok-employees-block__filter-section {
    @include grid-columns(3, calc(var(--grid-columns) - 4));
}
.storyblok-employees-block__block {
    @include grid-columns(4, calc(var(--grid-columns) - 6));
}
.storyblok-employees-block__block,
.storyblok-employees-block__filter-section,
.storyblok-employees-block__empty-filter {
    @include tablet {
        @include grid-columns(1, calc(var(--grid-columns) - 1));
    }

    @include mobile {
        @include grid-columns(1, var(--grid-columns));
    }
}
.storyblok-employees-block {
    @include fluid('padding-bottom', 30px, 60px);
}
.storyblok-employees-block__empty-filter {
    padding: 40px 29px;
    background-color: var(--c-white);
}
  </style>
