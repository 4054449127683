<template>
  <UtilRendererStoryblok
    :data="mainContent || []"
    :rtl="rtl"
    :slugs="slugs"
  />
</template>

<script setup>
defineProps({
    mainContent: {
        type: Object,
        default: () => {},
    },

    slugs: {
        type: Object,
        default: () => {},
    },

    rtl: {
        type: Boolean,
        default: false,
    },
});

definePageMeta({
    layout: 'default',
});

/*
    Redirect to the default locale if the current locale is not supported
*/
const storyStore = useStoryStore();
const locales = computed(() => storyStore.getLocales);
const { locale: currentLocale } = useI18n();
const route = useRoute();
const newRoute = ref('');
const slugsStore = useSlugsStore();
const allSlugs = slugsStore.getSlugs;
const translatedSlugs = ref(null);

const getTranslatedSlugs = (path) => {
    const replacedPath = path.replace(/^\/|\/$/g, '').replace(/en\//g, '').replace(/fr\//g, '').replace(/it\//g, '');

    const slugs = allSlugs.find(
        (item) => item.de === replacedPath
        || item.en === replacedPath
        || item.fr === replacedPath
        || item.it === replacedPath,
    );

    if (!slugs) {
        return null;
    }

    return slugs;
};

const router = useRouter();
const checkAndRedirect = (path) => {
    translatedSlugs.value = getTranslatedSlugs(path);

    if (!locales.value.includes(currentLocale.value)) {
        const newLocale = locales.value[0] === 'de' ? '' : locales.value[0];
        const newTranslatedSlug = translatedSlugs.value[newLocale === '' ? 'de' : newLocale];
        newRoute.value = `/${newLocale}/${newTranslatedSlug}`;

        if (newRoute.value.startsWith('//')) {
            newRoute.value = newRoute.value.replace('//', '/');
        }

        router.replace(newRoute.value);
    }
};

onMounted(() => {
    setTimeout(() => {
        checkAndRedirect(route.path);
    }, 300);
});
</script>
