<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-accordion is-narrow">
        <div v-if="title" class="storyblok-accordion__section__title">
          <AtomHeading class="storyblok-accordion__title" :text="title" font-size="h3" html-tag="h3" />
        </div>
        <BlockAccordion
          :items="mappedItems"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },
    items: {
        type: Object,
        required: true,
    },
});
const storyblokApi = useStoryblokApi();

const mappedItems = computed(() => props.items.map((item) => ({
    title: item.title,
    text: storyblokApi?.richTextResolver.render(item.text),
    link: item?.link?.cached_url,
    linkLabel: item.linkLabel,
    isInternal: item?.link?.linktype === 'story',

})));
</script>

<style lang="scss" scoped>
.storyblok-accordion {
    @include default-content-columns;
    @include fluid('margin-bottom', 30px, 60px);
}
.storyblok-accordion__section__title {
    padding: 26px 30px 26px 20px;
    margin-bottom: 3px;
    background: var(--c-white);
    color: var(--petrol-4);
    @include mobile {
        padding: 20px;
    }
}

</style>
