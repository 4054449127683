<template>
  <div class="block-download-hub">
    <div class="block-download-hub__search">
      <div
        class="block-download-hub__search-title"
        :class="{ 'is-active': searchFilterOpen }"
        @click="toggleOpen('search')"
        @keypress="toggleOpen('search')"
      >
        <div class="block-download-hub__search-title-wrapper">
          <p>{{ useStoryblokTranslation('general', 'search_term') }}</p>
          <IonIcon
            class="block-download-hub__search-title-icon-magnifying"
            icon-name="search"
          />
        </div>
        <IonIcon
          class="block-download-hub__search-title-icon"
          icon-name="chevron-down"
        />
      </div>
      <div
        class="block-download-hub__search-content-wrapper"
        :class="{ 'is-open': searchFilterOpen }"
      >
        <AtomInputText
          class="block-download-hub__search-input"
          name="search"
          label="Search"
          @set-input="filterText"
        />
        <IonIcon
          class="block-download-hub__search-title-icon-magnifying"
          icon-name="search"
        />
      </div>
    </div>
    <div class="block-download-hub__filter-primary">
      <div
        class="block-download-hub__filter-primary-title"
        :class="{ 'is-active': mainFilterOpen }"
        @click="toggleOpen('main')"
        @keypress="toggleOpen('main')"
      >
        <p>{{ useStoryblokTranslation('general', 'narrow_search') }}</p>
        <IonIcon icon-name="chevron-down" />
      </div>
      <div
        class="block-download-hub__content-wrapper"
        :class="{ 'is-open': mainFilterOpen }"
      >
        <div class="block-download-hub__filter-primary-items">
          <div
            v-for="filter, index in filterPrimary"
            :key="filter._uid"
            class="block-download-hub__filter-primary-item"
            :class="{
              'is-inactive': selectedFilterIndex !== index && selectedFilterIndex !== null,
              'is-active': selectedFilterIndex === index,
            }"
            @click="setFilter(filter, index)"
            @keypress="setFilter(filter, index)"
          >
            <AtomImage
              v-if="filter.image?.filename"
              class="block-download-hub__filter-primary-item-image"
              :class="{ 'is-department': departmentSelected }"
              :image="filter.image"
              width="450px"
              height="350px"
              provider="storyblok"
            />
            <div
              class="block-download-hub__filter-primary-item-title"
              :class="{ 'is-department': departmentSelected }"
              :style="`background: var(--c-${filter.color}-11);
                    color: var(--c-${filter.color}-1);`"
            >
              <p>{{ filter.title }}</p>
              <IonIcon
                icon-name="chevron-right"
                class="block-download-hub__filter-primary-department-icon"
                :color="`var(--c-${filter.color}-1)`"
              />
            </div>
          </div>
        </div>

        <div class="block-download-hub__swiper-wrapper">
          <swiper
            :direction="'horizontal'"
            :grab-cursor="true"
            :space-between="3"
            @slide-change="resetData()"
          >
            <swiper-slide
              v-for="(filter, index) in filterPrimary"
              :key="filter"
              @click="setFilter(filter, index)"
              @keypress="setFilter(filter, index)"
            >
              <AtomImage
                v-if="filter.image?.filename"
                class="block-download-hub__filter-primary-item-image"
                :class="{ 'is-department': departmentSelected }"
                :image="filter.image"
                width="450px"
                height="350px"
                provider="storyblok"
              />
              <div
                class="block-download-hub__filter-primary-item-title"
                :class="{ 'is-department': departmentSelected }"
                :style="`background: var(--c-${filter.color}-11);
                      color: var(--c-${filter.color}-1);`"
              >
                <p>{{ filter.title }}</p>
                <IonIcon
                  icon-name="chevron-right"
                  class="block-download-hub__filter-primary-department-icon"
                  :color="`var(--c-${filter.color}-1)`"
                />
              </div>
            </swiper-slide>
          </swiper>
        </div>

        <div
          v-if="departmentSelected && departments"
          class="block-download-hub__filter-primary-departments"
        >
          <div
            v-for="department, index in departments"
            :key="department._uid"
            class="block-download-hub__filter-primary-department"
            :class="{
              'is-inactive': selectedDepartmentIndex !== index && selectedDepartmentIndex !== null,
              'is-active': selectedDepartmentIndex === index,
            }"
            @click="setActiveDepartment(department, index)"
            @keypress="setActiveDepartment(department, index)"
          >
            <AtomImage
              v-if="department.content.image?.filename"
              :image="department.content.image"
              provider="storyblok"
              class="block-download-hub__filter-primary-departments-image"
              height="350px"
              width="350px"
            />
            <div
              class="block-download-hub__filter-primary-departments-title"
              :class="{ 'is-active': selectedDepartmentIndex === index }"
              :style="`--color_4: ${department.content.color_4}; --color_1: ${department.content.color_1}; --color_11: ${department.content.color_11};`"
            >
              <p>
                {{ currentLocale === 'de'
                  ? department.name
                  : department.translated_slugs.find((name) => name.lang === currentLocale)?.name }}
              </p>
              <IonIcon
                class="block-download-hub__filter-secondary-department-icon"
                icon-name="chevron-right"
                :color="department.content.color_1"
              />
            </div>
          </div>
        </div>

        <!-- Mobile Departments -->
        <div class="block-download-hub__swiper-wrapper">
          <swiper
            v-if="departmentSelected && departments"
            class="block-download-hub__swiper-departments"
            :direction="'horizontal'"
            :grab-cursor="true"
            :space-between="3"
            @slide-change="setActiveDepartment(
              departments[swiperRefDepartments[0].swiperRef.value.activeIndex],
              swiperRefDepartments[0].swiperRef.value.activeIndex)"
          >
            <swiper-slide
              v-for="(department, index) in departments"
              ref="swiperRefDepartments"
              :key="department"
              class="block-download-hub__filter-primary-department"
              :class="{
                'is-inactive': selectedDepartmentIndex !== index && selectedDepartmentIndex !== null,
                'is-active': selectedDepartmentIndex === index,
              }"
              @click="setActiveDepartment(department, index)"
              @keypress="setActiveDepartment(department, index)"
            >
              <AtomImage
                v-if="department.content.image?.filename"
                :image="department.content.image"
                provider="storyblok"
                class="block-download-hub__filter-primary-departments-image"
                height="350px"
                width="350px"
              />

              <div
                class="block-download-hub__filter-primary-departments-title"
                :style="`--color_4: ${department.content.color_4}; --color_1: ${department.content.color_1}; --color_11: ${department.content.color_11};`"
              >
                <p>
                  {{ currentLocale === 'de'
                    ? department.name
                    : department.translated_slugs.find(
                      (name) => name.lang === currentLocale)?.name }}
                </p>
                <IonIcon
                  class="block-download-hub__filter-primary-department-icon"
                  icon-name="chevron-right"
                  :color="department.content.color_1"
                />
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div v-if="programs" class="block-download-hub__filter-primary-programs">
          <div
            v-for="program, index in programs"
            :key="program.uid"
            class="block-download-hub__filter-primary-program"
            :class="{ 'is-active': selectedProgramIndex === index }"
            :style="selectedProgramIndex === index
              ? `background: ${program.content.department.content.color_4}; color: var(--c-white);`
              : `background: ${program.content.department.content.color_11}; color: ${program.content.department.content.color_1};`"
            @click="setActiveProgram(program, index)"
            @keypress="setActiveProgram(program, index)"
          >
            <p>
              {{ currentLocale === 'de'
                ? program.name
                : program.translated_slugs.find((name) => name.lang === currentLocale)?.name }}
            </p>
            <IonIcon
              class="block-download-hub__filter-primary-program-icon"
              icon-name="chevron-right"
              :style="`--department-color: ${program.content.department.content.color_1}`"
            />
          </div>
        </div>
        <div v-if="countries" class="block-download-hub__filter-primary-countries">
          <div
            v-for="country, index in countries"
            :key="country._uid"
            class="block-download-hub__filter-primary-country"
            :class="{ 'is-active': selectedCountryIndex === index }"
            @click="setActiveCountry(country, index)"
            @keypress="setActiveCountry(country, index)"
          >
            <p>{{ country.name }}</p>
            <IonIcon
              class="block-download-hub__filter-primary-country-icon"
              icon-name="chevron-right"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="downloadFiles" class="block-download-hub__filter-secondary">
      <p>
        {{ useStoryblokTranslation('general', 'filter') }}
      </p>
      <div class="block-download-hub__filter-secondary-selectfields">
        <AtomSingleselect
          v-for="filter in activeSecondryFilter"
          :key="filter.title"
          :options="filter.options"
          :label="filter.label"
          :name="filter.name"
          class="block-filter__select"
          @set-data="setSecondryFilter"
        />
      </div>
    </div>
    <div
      v-if="downloadFiles"
      :key="rerenderBlock"
      class="block-download-hub__filtered-files"
    >
      <div
        v-for="(file, index) in downloadFiles"
        :key="file._uid"
        class="block-download-hub__file desktop"
      >
        <AtomItemDownloadCard
          v-bind="{ ...file?.content }"
          :date="file?.first_published_at"
          :file-data="file?.fileData"
        >
          <template #select>
            <AtomSelect
              v-if="file.content?.files"
              :options="convertLangDataOptions(file.content?.files)"
              :card-index="index"
              class="block-download-hub__select"
              :color-bg="file.content?.department?.content?.color_11"
              :color-text="file.content?.department?.content?.color_1"
              :color-icon="file.content?.department?.content?.color_4"
              :has-initial-emit="true"
              @set-data="getLangData"
            />
          </template>
        </AtomItemDownloadCard>
      </div>
      <div class="block-download-hub__swiper-wrapper">
        <swiper
          :direction="'horizontal'"
          :grab-cursor="true"
          :space-between="3"
        >
          <swiper-slide
            v-for="(file, index) in downloadFiles"
            :key="file._uid"
            class="block-download-hub__file"
          >
            <AtomItemDownloadCard
              v-bind="{ ...file?.content }"
              :date="file?.first_published_at"
              :file-data="file?.fileData"
            >
              <template #select>
                <AtomSelect
                  v-if="file.content?.files"
                  :options="convertLangDataOptions(file.content?.files)"
                  :card-index="index"
                  class="block-download-hub__select"
                  :color-bg="file.content?.department?.content?.color_11"
                  :color-text="file.content?.department?.content?.color_1"
                  :color-icon="file.content?.department?.content?.color_4"
                  @set-data="getLangData"
                />
              </template>
            </AtomItemDownloadCard>
            <div
              v-if="downloadFiles.length === 0"
              class="block-download-hub__no-result"
            >
              <p>{{ useStoryblokTranslation('general', 'no_results') }}</p>
              <AtomRichtext :html="noResults" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div
        v-if="downloadFiles.length === 0"
        class="block-download-hub__no-result"
      >
        <p>{{ useStoryblokTranslation('general', 'no_results') }}</p>
        <AtomRichtext :html="noResults" />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    filterPrimary: {
        type: Array,
        default: () => [],
    },

    filterSecondary: {
        type: Array,
        default: () => [],
    },

    defaultState: {
        type: String,
        default: '',
    },

    defaultDepartment: {
        type: String,
        default: '',
    },

    defaultDepartmentNational: {
        type: String,
        default: '',
    },
});

// import Swiper core and required modules
const {
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const {
    filterPrimary,
    defaultState,
    defaultDepartment,
    defaultDepartmentNational,
} = toRefs(props);

const downloadFiles = ref(null);
const storyblokApi = useStoryblokApi();
const filterData = ref(null);
const selectedFilterIndex = ref(null);
const selectedDepartmentIndex = ref(null);
const departmentSelected = ref(false);
const departments = ref(null);
const dataMainFilter = ref(null);
const dataDepartmentFilter = ref(null);
const programs = ref(null);
const selectedProgramIndex = ref(null);
const countries = ref(null);
const selectedCountryIndex = ref(null);
const noResults = storyblokApi?.richTextResolver.render(useStoryblokTranslation('general', 'no_results', true));
const mainFilterOpen = ref(defaultState.value === 'open_main');
const searchFilterOpen = ref(defaultState.value === 'open_search');
const sortData = ref(null);
const clientDeviceStore = useClientDeviceStore();
const swiperRefDepartments = ref(null);

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const rerenderBlock = ref(0);
const forceRerender = () => {
    rerenderBlock.value += 1;
};

const getLangData = (data) => {
    if (!data.url) {
        return;
    }

    downloadFiles.value[data.index].fileData = {
        url: data.url,
        extention: getFileExtension(data.url),
    };
};

const getDownloadFiles = async () => {
    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'download-hub',
            version: 'published',
            resolve_relations: 'Download File.department,Download File.country,Download File.program',
            filter_query: filterData.value,
            sort_by: sortData.value,
            language,
        });

        downloadFiles.value = data.stories;

        forceRerender();
    } catch (e) {
        useSentryError(e);
    }
};

const getDepartments = async (ids) => {
    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'data-sources/departments',
            version: 'published',
            by_uuids: ids,
            language,
        });

        departments.value = data.stories;
    } catch (e) {
        useSentryError(e);
    }
};

const getPrograms = async (ids) => {
    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'data-sources/programs',
            version: 'published',
            resolve_relations: 'Program.department,',
            filter_query: {
                department: {
                    in: ids,
                },
            },
            language,
            sort_by: 'name:asc',
        });

        programs.value = data.stories;
    } catch (e) {
        useSentryError(e);
    }
};

const getCountries = async () => {
    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'data-sources/countries',
            version: 'published',
            language,
        });

        countries.value = data.stories;
    } catch (e) {
        useSentryError(e);
    }
};

const activeSecondryFilter = ref(null);

const setActiveMainFilter = (index) => {
    selectedFilterIndex.value = index;
    selectedDepartmentIndex.value = null;
};

const sortFirstPublished = ref(null);
const sortAlphabetical = ref(null);

const setSecondryFilter = (data) => {
    if (data.name === 'date') {
        // sort by published date
        sortFirstPublished.value = `first_published_at:${data.value[0]}`;
    }

    if (data.name === 'sort') {
        // sort by title
        sortAlphabetical.value = `content.title:${data.value[0]}`;
    }

    if (Array.isArray(data?.value) && data.name === 'thema' && data.value.length > 0) {
        filterData.value = {
            ...filterData.value,
            themenbereich: {
                in: data.value[0],
            },
        };
    } else {
        filterData.value = {
            ...filterData.value,
            themenbereich: null,
        };
    }

    if (Array.isArray(data?.value) && data.name === 'plattform' && data.value.length > 0) {
        filterData.value = {
            ...filterData.value,
            department: {
                in: data.value[0],
            },
        };
    } else {
        filterData.value = {
            ...filterData.value,
            department: null,
        };
    }

    if (Array.isArray(data?.value) && data.name === 'programm' && data.value.length > 0) {
        filterData.value = {
            ...filterData.value,
            program: {
                any_in_array: data.value[0],
            },
        };
    } else {
        filterData.value = {
            ...filterData.value,
            program: null,
        };
    }

    // handling sort data
    if (sortFirstPublished.value && sortAlphabetical.value) {
        sortData.value = `${sortFirstPublished.value},${sortAlphabetical.value}`;
    } else if (sortFirstPublished.value) {
        sortData.value = sortFirstPublished.value;
    } else if (sortAlphabetical.value) {
        sortData.value = sortAlphabetical.value;
    }

    if (data.name === 'format') {
        if (data.value.length > 0) {
            // filter out the file type
            filterData.value = {
                ...filterData.value,
                format: {
                    like: `*${data.value[0]}`,
                },
            };
        } else {
            filterData.value = {
                ...filterData.value,
                format: null,
            };
        }
    }

    getDownloadFiles();
};

const convertLangDataOptions = (data) => {
    const options = [];
    data.forEach((item) => {
        options.push({
            language: item.language,
            value: item.file?.filename,
        });
    });

    return options;
};

const setActiveDepartment = (data, index) => {
    if (selectedDepartmentIndex.value === index) {
        selectedDepartmentIndex.value = null;
        selectedProgramIndex.value = null;
        programs.value = null;

        filterData.value = {
            department: {
                in: dataMainFilter.value.department.toString(),
            },
        };

        getDownloadFiles();
        return;
    }

    selectedDepartmentIndex.value = index;
    selectedProgramIndex.value = null;
    dataDepartmentFilter.value = data;

    filterData.value = {
        department: {
            in: dataDepartmentFilter.value.uuid,
        },
    };

    getPrograms(data.uuid);
    getDownloadFiles();
};

const setActiveProgram = (data, index) => {
    if (selectedProgramIndex.value === index) {
        selectedProgramIndex.value = null;

        filterData.value = {
            department: {
                in: dataDepartmentFilter.value.uuid,
            },
        };

        getDownloadFiles();
        return;
    }

    selectedProgramIndex.value = index;
    filterData.value = {
        program: {
            in_array: data.uuid,
        },
    };

    getDownloadFiles();
};

const setActiveCountry = (data, index) => {
    if (selectedCountryIndex.value === index) {
        selectedCountryIndex.value = null;

        filterData.value = {
            department: {
                in: dataMainFilter.value.department.toString(),
            },
        };

        getDownloadFiles();
        return;
    }

    selectedCountryIndex.value = index;
    filterData.value = {
        country: {
            in_array: data.uuid,
        },
    };

    getDownloadFiles();
};

const { throttledExecute } = useThrottledExecute(700);
const filterText = (data) => {
    const suffix = currentLocale.value === defaultLocale ? '' : `__i18n__${currentLocale.value}`;

    filterData.value = {
        [`title${suffix}`]: {
            like: `*${data}*`,
        },
    };

    if (data && data.length > 2) {
        throttledExecute(() => {
            getDownloadFiles();
        });
    }

    if (data.length === 0 && !defaultDepartment.value) {
        throttledExecute(() => {
            downloadFiles.value = null;
        });
    }
};

const resetData = () => {
    filterData.value = null;
    downloadFiles.value = null;
    departmentSelected.value = false;
    selectedDepartmentIndex.value = null;
    dataMainFilter.value = null;
    countries.value = null;
    programs.value = null;
};

const setFilter = async (data, index) => {
    // if index is same as selectedFilterIndex, set selectedFilterIndex to null
    if (selectedFilterIndex.value === index) {
        resetData();

        setActiveMainFilter(null);
        return;
    }

    dataMainFilter.value = data;
    programs.value = null;
    activeSecondryFilter.value = filterPrimary.value[index].filterSecondary;

    // if index is 1 (department), set departmentSelected to true
    if (index === 1) {
        departmentSelected.value = true;
        await getDepartments(data.department.toString());

        if (clientDeviceStore.isMobile) {
            setActiveDepartment(departments.value[0], 0);
        }
    } else {
        departmentSelected.value = false;
    }

    // if index is 2 (country), set countries to null
    if (index === 2) {
        getCountries();
    } else {
        countries.value = null;
    }

    filterData.value = {
        department: {
            in: data.department.toString(),
        },
    };

    setActiveMainFilter(index);
    getDownloadFiles();
};

const toggleOpen = (type) => {
    resetData();

    if (type === 'main') {
        mainFilterOpen.value = !mainFilterOpen.value;
        searchFilterOpen.value = false;
    } else if (type === 'search') {
        searchFilterOpen.value = !searchFilterOpen.value;
        mainFilterOpen.value = false;
    }
};

// set default data if defaultState is open_main
if (defaultDepartment.value && defaultState.value === 'open_main') {
    if (defaultDepartment.value === 'stiftung') {
        setFilter(filterPrimary.value[0], 0);
    } else if (defaultDepartment.value === 'schweiz') {
        setFilter(filterPrimary.value[1], 1);

        if (defaultDepartmentNational.value) {
            setTimeout(() => {
                // find the index of the department where uuid = defaultDepartmentNational.value
                const index = departments.value.findIndex(
                    (department) => department.uuid === defaultDepartmentNational.value,
                );
                setActiveDepartment(departments.value[index], index);
            }, 500);
        }
    } else if (defaultDepartment.value === 'international') {
        setFilter(filterPrimary.value[2], 2);
    }
}

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
.block-download-hub {
    display: flex;
    flex-direction: column;
    row-gap: 3px;

    .ion-icon {
        transition: transform 0.3s $EASE_IN_OUT--BACK;
    }

    .swiper {
        display: none;
        overflow: visible;

        @include mobile {
            display: block;
            width: calc(100% - var(--page-padding));
            margin-left: 0;
        }
    }
}

.block-download-hub__search {
    display: flex;
    width: 100%;
    flex-direction: column;
    background: var(--c-white);
}

.block-download-hub__search-title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 21px 30px;
    column-gap: 20px;
    cursor: pointer;

    p {
        color: v-bind(color1);
        font-family: var(--f-family--regular);
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);

        @include mobile {
            font-size: var(--f-size--description);
            line-height: var(--l-height--description);
        }
    }

    &.is-active {
        padding: 40px 30px;

        p{
            font-family: var(--f-family--black);
        }

        .block-download-hub__search-title-icon {
            transform: rotate(180deg);
        }

        .block-download-hub__search-title-icon-magnifying {
            display: none;
        }

        @include mobile {
            padding: 10px;
        }
    }

    @include mobile {
        padding: 10px;
    }
}

.block-download-hub__search-title-wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.block-download-hub__search-content-wrapper {
    display: flex;
    overflow: hidden;
    height: 0;
    align-items: center;
    column-gap: 20px;

    &.is-open {
        height: 100%;
        padding: 0 30px 40px 30px;

        @include mobile {
            padding: 0 10px 20px 10px;
        }
    }
}

.block-download-hub__search-input {
    width: 444px;

    @include mobile {
        width: 100%;
    }
}

.block-download-hub__filter-primary-title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 21px 30px;
    color: v-bind(color1);
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);

    &.is-active {
        padding: 40px 30px;
        font-family: var(--f-family--black);

        .ion-icon {
            transform: rotate(180deg);
        }

        @include mobile {
            padding: 10px;
        }
    }

    @include mobile {
        padding: 10px;
        font-size: var(--f-size--description);
        line-height: var(--l-height--description);
    }
}

.block-download-hub__filter-primary-items {
    display: grid;
    column-gap: 3px;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @include mobile {
        display: none;
    }
}

.block-download-hub__filter-primary-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease;

    &.is-inactive {
        opacity: 0.2;
    }

    &.is-active {
        .ion-icon {
            transform: rotate(90deg);
        }
    }
}

.block-download-hub__filter-primary-item-image {
    @include fluid('min-height',203px ,324px);

    width: 100%;
    aspect-ratio: 1/1;

    &.is-department {
        display: none;
    }
}

.block-download-hub__filter-primary-item-title {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    font-family: var(--f-family--black);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);

    /* stylelint-disable-next-line no-descending-specificity */
    p {
        hyphens: auto;
    }

    &.is-department {
        padding: 20px 40px;

    }

    @include mobile {
        padding: 20px;
    }
}

.block-download-hub__filter-primary-departments {
    display: grid;
    column-gap: 3px;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @include mobile {
        display: none;
    }
}

.block-download-hub__filter-primary-department {
    overflow: hidden;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease;

    &.is-inactive {
        opacity: 0.2;
    }

    &.is-active {
        .ion-icon {
            transform: rotate(90deg);
        }
    }
}

.block-download-hub__filter-primary-departments-image {
    width: 100%;
    aspect-ratio: 1/1;
}

.block-download-hub__filter-primary-departments-title {
    @include fluid('padding-left', 20px, 40px);
    @include fluid('padding-right', 20px, 40px);

    display: flex;
    align-items: center;
    padding-top: 22px;
    padding-bottom: 20px;
    background: var(--color_11);
    color: var(--color_1);
    column-gap: 10px;
    font-family: var(--f-family--black);
    font-size: var(--f-size--h5);
    line-height: var(--l-height--h5);

    @include desktop {
        font-size: var(--f-size--h6);
        line-height: var(--l-height--h6);
    }

    @include mobile {
        padding: 20px;
        padding-bottom: 16px;
        background: var(--color_4) !important;
        color: var(--c-white) !important;
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);
    }

    .is-active &{
        background: var(--color_4);
        color: var(--c-white);
    }
}

.block-download-hub__filter-primary-programs {
    display: none;
    margin-top: 3px;
    column-gap: 3px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 3px;

    @include mobile {
        display: none;
        flex-direction: column;
    }
}

.block-download-hub__filter-primary-program {
    @include fluid('padding-left', 20px, 40px);
    @include fluid('padding-right', 20px, 40px);

    display: flex;
    height: 116px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-family: var(--f-family--black);
    font-size: var(--f-size--h6);
    line-height: var(--l-height--h6);

    /* stylelint-disable-next-line no-descending-specificity */
    p {
        hyphens: auto;
    }

    &.is-active {
        .ion-icon {
            transform: rotate(90deg);
        }
    }

    @include mobile {
        height: 62px;
        padding: 0 20px;
    }
}

.block-download-hub__filter-primary-countries {
    display: none;
    margin-top: 3px;
    column-gap: 3px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 3px;

    @include mobile {
        display: none;
        flex-direction: column;
    }
}

.block-download-hub__filter-primary-country {
    display: flex;
    height: 116px;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    background: var(--c-coral-11);
    color: var(--c-coral-1);
    cursor: pointer;
    font-family: var(--f-family--black);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);

    /* stylelint-disable-next-line no-descending-specificity */
    p {
        hyphens: auto;
    }

    &.is-active {
        background: var(--c-coral-4);
        color: var(--c-white);
        .ion-icon {
            transform: rotate(90deg);
        }
    }

    @include mobile {
        height: 62px;
        padding: 0 20px;
    }
}

.block-download-hub__filter-secondary {
    @include z-index('filter');

    display: flex;
    justify-content: space-between;
    padding: 21px 29px;
    background: var(--c-white);

    /* stylelint-disable-next-line no-descending-specificity */
    p {
        color: v-bind(color1);
        font-family: var(--f-family--regular);
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);
    }

    .block-filter__select {
        margin-left: 0;
    }

    @include mobile {
        flex-direction: column;
        padding: 10px;
        row-gap: 10px;
    }
}

.block-download-hub__filter-secondary-selectfields {
    display: flex;
    column-gap: 5px;
    row-gap: 5px;

    @include mobile {
        flex-wrap: wrap;
    }
}

.block-download-hub__filtered-files {
    display: grid;
    background: var(--c-background);
    column-gap: 3px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 3px;

    @include mobile {
        display: block;
    }
}

.block-download-hub__file {
    display: flex;
    flex-direction: column;

    &.desktop {
        @include mobile {
            display: none;
        }
    }
}

.block-download-hub__no-result {
    padding: 40px 120px;
    grid-column: 1 / -1;

    /* stylelint-disable-next-line no-descending-specificity */
    p {
        margin-bottom: 20px;
        font-family: var(--f-family--thin);
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);
    }

    @include mobile {
        padding: 30px;
    }
}

.block-download-hub__content-wrapper {
    overflow: hidden;
    height: 0;

    &.is-open {
        height: 100%;
        padding: 0 30px 40px 30px;

        @include mobile {
            overflow: visible;
            padding: 0;
        }
    }

}

.block-download-hub__swiper-wrapper {
    overflow: hidden;
    width: calc(100% + var(--page-padding));
}

.block-download-hub__filter-primary {
    background: var(--c-white);
}
</style>
