<template>
  <div
    class="block-item-contact-card"
  >
    <div class="block-item-contact-card__content">
      <div v-if="title && !isTablet" class="block-item-contact-card__title">
        <h3>{{ title }}</h3>
      </div>

      <AtomTag class="block-item-contact-card__tag" variant="inverted" :text="tag" :color-schema="globalColor" />

      <div class="block-item-contact-card__info">
        <p v-if="dataContact?.department" class="block-item-contact-card__department">
          {{ dataContact?.department }}
        </p>
        <p v-if="dataContact?.name" class="block-item-contact-card__name">
          {{ dataContact?.name }}
        </p>
        <p v-if="dataContact?.position?.length > 0" class="block-item-contact-card__position">
          {{ dataContact?.position }}
        </p>
      </div>
      <div v-if="!isTablet" class="block-item-contact-card__contact">
        <a
          v-if="dataContact?.email?.length > 0"
          :href="`mailto:${email}`"
          class="block-item-contact-card__mail"
        >
          <p>{{ dataContact?.email }}</p>
        </a>
        <a
          v-if="dataContact?.phone?.length > 0"
          :href="`tel:${dataContact?.phone}`"
          class="block-item-contact-card__phone"
        >
          <p>{{ dataContact?.phone }}</p>
        </a>
      </div>

      <UtilRouteLink
        v-if="link?.cached_url"
        class="block-item-contact-card__link"
        :link="link.cached_url"
      >
        <AtomButton
          class="block-item-contact-card__button"
          :text="linkLabel"
          :color-schema="globalColor ? globalColor : 'petrol'"
          variant="inverted"
          html-tag="div"
        />
      </UtilRouteLink>
    </div>
    <AtomImage
      v-if="dataContact?.image?.filename"
      class="block-item-contact-card__image"
      :image="dataContact?.image"
      provider="storyblok"
      lazy="false"
      height="510px"
      width="390px"
    />
  </div>
</template>

<script setup>

const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    person: {
        type: String,
        required: true,
    },
    tag: {
        type: String,
        default: '',
    },
    linkLabel: {
        type: String,
        default: '',
    },
    link: {
        type: Object,
        default: () => {},
    },
    globalColor: {
        type: String,
        default: '',
    },
});

const colorTopLevel = inject('colorSchema');
const colorBackground = computed(() => (props.globalColor
    ? `var(--c-${props.globalColor}-11)`
    : `var(--c-${colorTopLevel.value}-11)`));
const colorTextDark = computed(() => (props.globalColor
    ? `var(--c-${props.globalColor}-4)`
    : `var(--c-${colorTopLevel.value}-4)`));
const colorText = computed(() => (props.globalColor
    ? `var(--c-${props.globalColor}-1)`
    : `var(--c-${colorTopLevel.value}-1)`));

const clientStore = useClientDeviceStore();
const isTablet = computed(() => (clientStore.isTablet));

const dataContactUID = computed(() => props.person);
const dataContact = ref([]);

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const storyblokApi = useStoryblokApi();
const getContact = async (uuids) => {
    if (uuids.length === 0) return;
    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'data-sources/people',
            version: 'published',
            by_uuids: uuids,
            language,
        });
        const [person] = data.stories;
        dataContact.value = person?.content;
    } catch (e) {
        useSentryError(e);
    }
};
onMounted(async () => {
    await getContact(dataContactUID.value);
});

</script>
<style lang="scss" scoped>
.block-item-contact-card {
    display: flex;
    gap: 3px;
    grid-column-end: span 2;
    @include tablet {
        flex-direction: column-reverse;
    }
}
.block-item-contact-card__tag {
    align-self: flex-start;
}
.block-item-contact-card__content ,
.block-item-contact-card__image {
    flex-basis: 50%;
}

.block-item-contact-card__content {
    display: flex;
    flex-direction: column;
    background: v-bind(colorBackground);
    font-family: var(--f-family--thin);
    @include fluid('padding', 20px, 40px);
    @include tablet {
        flex-basis: auto;
    }
}
.block-item-contact-card__title {
    padding-bottom: 20px;
    font-family: var(--f-family--black);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);
}

.block-item-contact-card__position ,
.block-item-contact-card__text,
.block-item-contact-card__contact,
.block-item-contact-card__name {
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-item-contact-card__text {
    font-family: var(--f-family--thin);
    @include tablet {
        padding-bottom: 20px;
    }
}
.block-item-contact-card__info {
    color: v-bind(colorText);
}
.block-item-contact-card__department {
    padding-top: 20px;
    font-size: var(--f-size--span);
    line-height: var(--l-height--span);
    @include tablet {
        padding-top: 5px;
    }
}
.block-item-contact-card__contact {
    padding: 20px 0 40px 0;
    color: v-bind(colorTextDark);
    font-family: var(--f-family--thin);
}
.block-item-contact-card__name {
    font-family: var(--f-family--bold);

    @include tablet {
        padding-bottom: 5px;
    }
}
.block-item-contact-card__position {
    @include tablet {
        padding-bottom: 20px;
    }
}

  </style>
