<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-teaser-curated">
        <BlockTeaserCurated :items="mappedItems" />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        required: true,
        default: () => [],
        validator: (value) => value.length > 0,
    },
});

const rawItems = computed(() => props.items);
const mappedItems = ref([]);

// get articles from items
const articlesUUIDS = computed(() => rawItems.value.map((item) => (
    item.article ? item.article : '')));
const articles = ref([]);

const storyblokApi = useStoryblokApi();
const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const getArticles = async (uuids) => {
    if (uuids.length === 0) return;

    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'news/news-article',
            version: 'published',
            by_uuids: uuids.join(','),
            resolve_relations: 'News.department,News.type',
            sort_by: 'first_published_at:desc',
            language,
        });

        articles.value = data.stories;

        if (articles.value.length === 0) return;

        rawItems.value.forEach((item) => {
            const article = articles.value.find(
                (fetchedArticle) => fetchedArticle.uuid === item.article,
            );
            if (article) {
                mappedItems.value.push({
                    article: {
                        title: article.content.title,
                        lead: article.content.lead,
                        url: article.default_full_slug,
                        date: article.first_published_at,
                        type: article.content.type.name,
                        department: {
                            name: article.content.department.translated_slugs?.find(
                                (slug) => slug.lang === currentLocale.value,
                            ).name || article.content.department.name,
                            color_3: article.content.department.content.color_3,
                        },
                        image: {
                            ...article.content.image_video,
                            provider: 'storyblok',
                        },
                    },
                    image_position: item.image_position,
                    size: item.size,
                });
            }
        });
    } catch (e) {
        useSentryError(e);

        // if we can't fetch the articles, we just use the raw items
        mappedItems.value = rawItems.value;
    }
};

onMounted(async () => {
    await getArticles(articlesUUIDS.value);
});

</script>

<style lang="scss" scoped>
.storyblok-teaser-curated {
    @include grid-columns(4, 12);
    @include fluid('margin-bottom', 50px, 80px);
}
</style>
