<template>
  <div class="atom-item-slider-participation">
    <UtilWrapperLayout>
      <div class="atom-item-slider-participation__grid">
        <div class="atom-item-slider-participation__counter">
          {{ counter }}
        </div>
        <div class="atom-item-slider-participation__wrapper">
          <div class="atom-item-slider-participation__tags">
            <AtomTag
              v-for="dataTag in dataTags"
              :key="dataTag?._uid"
              :text="dataTag?.name"
              :color-schema="color"
            />
          </div>
          <div class="atom-item-slider-participation__title">
            <AtomHeading
              :text="title"
              class="atom-item-slider-participation__title-dark"
              html-tag="h1"
              font-size="h1"
              :color-schema="colorSchema"
            />
            <AtomHeading
              :text="subtitle"
              class="atom-item-slider-participation__title-white"
              html-tag="h2"
              font-size="h2"
            />
            <div
              class="atom-item-slider-participation__btns"
            >
              <UtilRouteLink
                v-for="(button, index) in btn"
                :key="button?._uid"
                class="block-teaser-news__link"
                link="kontakt"
              >
                <AtomButton
                  :text="button?.linkLabel"
                  class="atom-item-slider-participation__btn"
                  :variant="index === 0 ? 'inverted' : 'primary' "
                  :color-schema="color"
                  html-tag="div"
                />
              </UtilRouteLink>
            </div>
          </div>
        </div>
      </div>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    subtitle: {
        type: String,
        default: '',
    },
    tags: {
        type: Array,
        default: () => [],
    },
    btn: {
        type: Array,
        default: () => [],
    },
    phase: {
        type: String,
        default: '',
    },
    counter: {
        type: Number,
        default: 0,
    },
    color: {
        type: String,
        default: 'petrol',
    },
});
const colorText = computed(() => (props.color && `var(--c-${props.color}-1)`));
const bgColor = computed(() => (props.color && props.phase ? `var(--c-${props.color}-${props.phase})` : `var(--c-${props.color}-9)`));
const dataTagsUUIDS = computed(() => props.tags);
const dataTags = ref([]);

const storyblokApi = useStoryblokApi();

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const getdataTags = async (uuids) => {
    if (uuids.length === 0) return;

    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'data-sources/tags',
            version: 'published',
            by_uuids: uuids.join(','),
            language,
        });

        dataTags.value = data.stories;
    } catch (e) {
        useSentryError(e);
    }
};

onMounted(async () => {
    await getdataTags(dataTagsUUIDS.value);
});
</script>

<style lang="scss" scoped>
.atom-item-slider-participation {
    @include fluid('padding-top', 100px, 242px);
    @include fluid('padding-bottom', 162px, 208px);
    position: relative;
    height: 709px;
    background: v-bind(bgColor);
}

.atom-item-slider-participation__grid {
    @include grid-layout();
}
.atom-item-slider-participation__wrapper {
    padding-left: 8px;

    @include grid-columns(5,12);

    @include tablet {
        @include grid-columns(1,12);
    }
}
.atom-item-slider-participation__counter {
    @include grid-columns(2,3);
    @include fluid('font-size', 100px, 150px);
    color: v-bind(colorText);
    font-family: var(--f-family--thin);
    text-align: right;

    @include tablet {
        text-align: left;
        @include grid-columns(1,12);
    }
}
.atom-item-slider-participation__tags {
    @include fluid('gap', 5px, 10px);
    @include fluid('padding-bottom', 5px, 10px);
    display: flex;
    @include tablet {
        flex-wrap: wrap;
    }
}

.atom-item-slider-participation__title {
    margin-bottom: 30px;
    color: v-bind(colorText);
    hyphens: auto;

    .atom-item-slider-participation__title-dark {
        color: v-bind(colorText);
        font-family: var(--f-family--black);

        @include mobile {
            font-family: var(--f-family--bold);
        }
    }

    .atom-item-slider-participation__title-white {
        color: var(--c-white);
        font-family: var(--f-family--thin);
        @include fluid('padding-bottom', 20px, 39px);

    }

}

.atom-item-slider-participation__link {
    @include grid-columns(3,9);
    @include z-index('main');

    display: flex;
    align-items: center;
    color: var(--c-white);
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    .ion-icon {
        transition: transform 0.2s ease-out;
    }

    &:hover {
        .ion-icon {
            transform: translateX(10px);
        }
    }

    @include tablet {
        @include grid-columns(1,12);
    }
}
.atom-item-slider-participation__btns {
    @include fluid('gap', 5px, 10px);
    display: flex;
    padding-left: 11px;
    @include tablet {
        flex-wrap: wrap;
        padding-left: 0;
    }
}

</style>
