<template>
  <div class="block-form-contact__grid">
    <div
      class="block-form-contact__person"
      :class="{ 'is-one-person': filteredPeople.length === 1 }"
    >
      <div
        v-if="filteredPeople.length > 1"
        class="block-form-contact__person-multiple"
      >
        <div
          v-for="person in filteredPeople"
          :key="person"
          class="block-form-contact__person-card"
        >
          <AtomImage
            v-if="person.content?.image?.filename"
            :image="person.content.image"
            class="block-form-contact__person-card-image"
            provider="storyblok"
            :height="isTablet ? 208 : 360"
            :width="400"
          />
          <div class="block-form-contact__person-card-content">
            <div class="block-form-contact__person-department">
              {{ person.content.department }}
            </div>
            <div class="block-form-contact__person-name">
              {{ person.content.name }}
            </div>
            <div class="block-form-contact__person-position">
              {{ person.content.position }}
            </div>
            <div class="block-form-contact__person-contact-info">
              <a
                v-if="person.content.email"
                class="block-form-contact__person-email"
                :href="`mailto:${person.content.email}`"
              >
                {{ person.content.email }}
              </a>
              <a
                v-if="person.content.phone"
                class="block-form-contact__person-phone"
                :href="`Tel:${person.content.phone}`"
              >
                {{ person.content.phone }}
              </a>
            </div>
            <button
              class="block-form-contact__person-more-info"
              type="button"
              @click="() => { selectedPerson = person; isOpen = true; }"
            >
              {{ useStoryblokTranslation('general', 'more_info') }}
            </button>
          </div>
        </div>
      </div>
      <div
        v-else-if="filteredPeople.length === 1"
        class="block-form-contact__person-single"
      >
        <div
          v-for="person in filteredPeople"
          :key="person"
          class="block-form-contact__person-card single"
        >
          <AtomImage
            v-if="person.content?.image?.filename"
            :image="person.content.image"
            class="block-form-contact__person-card-image single"
            :class="{ 'is-logo': isLogo }"
            provider="storyblok"
          />
          <div class="block-form-contact__person-card-content single">
            <div class="block-form-contact__person-department">
              {{ person.content.department }}
            </div>
            <div class="block-form-contact__person-name">
              {{ person.content.name }}
            </div>
            <div class="block-form-contact__person-position">
              {{ person.content.position }}
            </div>
            <div class="block-form-contact__person-contact-info">
              <a
                v-if="person.content.email"
                class="block-form-contact__person-email"
                :href="`mailto:${person.content.email}`"
              >
                {{ person.content.email }}
              </a>
              <a
                v-if="person.content.phone"
                class="block-form-contact__person-phone"
                :href="`Tel:${person.content.phone}`"
              >
                {{ person.content.phone }}
              </a>
            </div>
            <button
              class="block-form-contact__person-more-info"
              type="button"
              @click="() => { selectedPerson = person; isOpen = true; }"
            >
              {{ useStoryblokTranslation('general', 'more_info') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="block-form-contact__form">
      <AtomHeading
        v-if="title"
        :text="title"
        class="block-form-contact__form-title"
        html-tag="h3"
        font-size="h3"
      />

      <AtomRichtext
        v-if="text"
        :html="text"
        class="block-form-contact__form-text"
      />

      <BlockFormController
        :fieldsets="fieldsets"
        :button-text="buttonText"
        @on-submit="onSubmit"
      />
    </div>
    <Transition name="fade-in">
      <BlockOverlayPerson
        v-if="isOpen"
        :person="selectedPerson"
        @close="() => { isOpen = false; }"
      />
    </Transition>
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    buttonText: {
        type: String,
        default: '',
    },

    fieldsets: {
        type: Array,
        default: () => [],
    },

    people: {
        type: Array,
        default: () => [],
    },

    defaultmail: {
        type: String,
        default: 'info@klik.ch',
    },

    sendToPeople: {
        type: Boolean,
        default: false,
    },

    isContactMedia: {
        type: Boolean,
        default: false,
    },

    isLogo: {
        type: Boolean,
        default: false,
    },
});

const filteredPeople = ref([]);
const peopleMail = ref('');

const isOpen = ref(false);
const selectedPerson = ref({});

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;
const clientDeviceStore = useClientDeviceStore();
const isTablet = computed(() => clientDeviceStore.isTablet);

const storyblokApi = useStoryblokApi();
const getPeople = async () => {
    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'data-sources/people',
            version: 'published',
            by_uuids: props.people.toString(),
            resolve_relations: 'Person.departments,Person.division',
            language,
        });

        filteredPeople.value = data.stories;
        peopleMail.value = filteredPeople.value.map((person) => person.content.email).join(',');
    } catch (e) {
        useSentryError(e);
    }
};

const localePath = useLocalePath();
const onSubmit = async (data) => {
    const body = {
        ...data,
        mailTo: props.sendToPeople ? peopleMail.value : props.defaultmail,
    };

    try {
        await useFetch(props.isContactMedia ? '/api/send_mail_media' : '/api/send_mail', {
            method: 'POST',
            body,
        });

        navigateTo(localePath('/kontakt-bestaetigung'));

        return data;
    } catch (e) {
        useSentryError(e);
    }

    return false;
};

onMounted(() => {
    getPeople();
});

const colorTopLevel = inject('colorSchema');

const colorBackground = computed(() => `var(--c-${colorTopLevel.value}-7)`);
const colorText = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>

<style lang="scss" scoped>
.block-form-contact__grid {
    @include grid-layout(18);

    @include mobile {
        @include grid-layout(3);
    }
}

.block-form-contact__person {
    @include grid-columns(3, 4);

    height: fit-content;

    @include tablet {
        @include grid-columns(1, 18);

        &.is-one-person {
            @include grid-columns(1, 9);
        }

        margin-bottom: 36px;
    }

    @include mobile {
        margin-right: calc(var(--page-padding) * -1);
        margin-left: calc(var(--page-padding) * -1);
    }
}

.block-form-contact__form {
    @include grid-columns(7, 10);

    padding: 40px;
    background: var(--c-white);

    @include tablet {
        @include grid-columns(1, 18);
    }
}

.block-form-contact__form-title {
    margin-bottom: 20px;
}

.block-form-contact__form-text {
    margin-bottom: 20px;
}

.block-form-contact__person-multiple {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 40px;

    @include tablet {
        flex-direction: row-reverse;
        column-gap: 10px;
    }

    @include mobile {
        @include grid-layout(2);

        column-gap: 3px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: 3px;
    }
}

.block-form-contact__person-single {
    display: flex;
    width: 100%;
}

.block-form-contact__person-card {
    display: flex;
    flex-direction: column;
    background: v-bind(colorBackground);

    @include tablet {
        width: 100%;
    }
}

.block-form-contact__person-card-image {
    width: 100%;
    height: 360px;

    &.atom-image__background {
        width: 100%;
    }

    &.single {
        @include grid-columns(1, 1);

        width: 100%;
        height: 360px;
    }

    @include tablet {
        height: 208px;
    }
}

.block-form-contact__person-card-content {
    display: flex;
    flex-direction: column;
    padding: 30px;
    color: var(--c-white);

    &.single {
        @include grid-columns(2, 1);

        background: v-bind(colorBackground);
    }

    @include mobile {
        padding: 20px;
    }
}

.block-form-contact__person-department {
    margin-bottom: 5px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.block-form-contact__person-name {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-form-contact__person-position {
    margin-bottom: 20px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-form-contact__person-contact-info {
    display: flex;
    flex-direction: column;

    @include mobile {
        display: none;
    }
}

.block-form-contact__person-more-info {
    display: none;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    @include mobile{
        display: block;
    }
}

.block-form-contact__person-email {
    color: v-bind(colorText);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    word-break: break-all;
}

.block-form-contact__person-phone {
    color: v-bind(colorText);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext)
}
</style>
