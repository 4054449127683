<template>
  <transition name="fade-in">
    <div v-if="isFiltered || isPrefiltered" class="block-news-filtered">
      <div v-if="news.length === 0" class="block-news-filtered__noArticles">
        <span>{{ useStoryblokTranslation('general', 'no_articles') }}</span>
      </div>
      <div class="block-news-filtered-wrapper">
        <UtilRouteLink
          v-for="item in news"
          :key="item.uuid"
          :link="item.default_full_slug"
          class="block-news-filtered__teaser block-news-filtered--mini"
        >
          <div class="block-news-filtered__imgWrapper">
            <AtomImage
              v-if="item.content?.image_video?.filename"
              class="block-news-filtered__img"
              :image="item.content.image_video"
              provider="storyblok"
              height="410px"
              width="410px"
            />
            <div
              v-if="item.content.activate_teaser_tag"
              class="block-news-filtered__tag"
            >
              <span>{{ item.content.teaser_tag }}</span>
            </div>
            <div
              v-if="item.content.activate_video_teaser"
              class="block-news-filtered__video-tag"
            >
              <span>{{ item.content.video_teaser }}</span>
            </div>
          </div>
          <div class="block-news-filtered__wrapper">
            <div
              v-if="item && item.content.department"
              class="block-news-filtered__date"
              :style="`color: ${item.content.department.content.color_7}`"
            >
              {{ dayjs(item.first_published_at).locale(currentLocale).format('DD. MMMM YYYY') }}
              <span> | </span>
              {{ item.content.department.translated_slugs?.find(
                (slug) => slug.lang === currentLocale,
              )?.name || item.content.department.name }}
            </div>
            <div class="block-news-filtered__title">
              {{ trimString(item.content.title, 35) }}
            </div>
            <div class="block-news-filtered__title-large">
              {{ trimString(item.content.title, 400) }}
            </div>
            <div class="block-news-filtered__teaser-large">
              <div class="block-news-filtered__lead">
                <span
                  v-if="item.content.type"
                  class="block-news-filtered_article"
                >{{ item.content.type.name }} |</span>
                {{ trimString(item.content.lead, 200) }}
              </div>
            </div>
            <IonIcon
              class="block-news-filtered__icon"
              icon-name="chevron-right"
            />
          </div>
        </UtilRouteLink>
      </div>
      <AtomButton
        v-if="showLoadMore"
        class="block-news-filtered__more-button"
        :text="useStoryblokTranslation('general', 'more_articles')"
        @click="loadMore()"
      />
    </div>
  </transition>
</template>

<script setup>
const props = defineProps({
    department: {
        type: String,
        default: '',
    },

    region: {
        type: String,
        default: '',
    },

    isInternational: {
        type: Boolean,
        default: false,
    },

    isNational: {
        type: Boolean,
        default: false,
    },
});

const {
    department,
    region,
    isInternational,
    isNational,
} = toRefs(props);

const { dayjs } = useDayJs();
const filterStore = useFilterStore();
const countries = computed(() => filterStore.getCountries);
const departments = computed(() => filterStore.getDepartments);
const regions = computed(() => filterStore.getRegions);
const typesOfArticle = computed(() => filterStore.getTypesOfArticle);
const dates = computed(() => filterStore.getYears);
const tags = computed(() => filterStore.getTags);
const isFiltered = computed(() => filterStore.getIsFilter);
const isPrefiltered = ref(false);
const news = ref([]);
const currentPage = ref(1);
const pageSize = ref(7);
const storyblokApi = useStoryblokApi();
const showLoadMore = ref(true);

// prefilled filter
if (department.value.length > 0) {
    filterStore.setDepartments(department.value);
    isPrefiltered.value = true;
}

if (region.value.length > 0) {
    filterStore.setRegions(region.value);
    isPrefiltered.value = true;
}

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const internationalDepartmentUUID = '6e8e4951-ab05-4981-80f4-736c9b4c7646';

const getArticles = async () => {
    const filterQuery = isInternational.value ? {

        department: {
            in: internationalDepartmentUUID,
        },

        ...(region.value.length === 0 ? {} : {
            region: {
                in: regions.value.toString(),
            },
        }),
        ...(countries.value.length === 0 ? {} : {
            country: {
                in: countries.value.toString(),
            },
        }),
        ...(typesOfArticle.value.length === 0 ? {} : {
            type: {
                in: typesOfArticle.value.toString(),
            },
        }),
        ...(tags.value.length === 0 ? {} : {
            tags: {
                any_in_array: tags.value.toString(),
            },
        }),
    } : {
        __or: [
            department.value.length === 0 ? {} : {
                department: {
                    in: departments.value.toString(),
                },
            },
            region.value.length === 0 ? {} : {
                region: {
                    in: regions.value.toString(),
                },
            },
            countries.value.length === 0 ? {} : {
                country: {
                    in: countries.value.toString(),
                },
            },
            typesOfArticle.value.length === 0 ? {} : {
                type: {
                    in: typesOfArticle.value.toString(),
                },
            },
            tags.value.length === 0 ? {} : {
                tags: {
                    any_in_array: tags.value.toString(),
                },
            },
        ],

        ...isNational.value ? {
            department: {
                not_in: internationalDepartmentUUID,
            },
        } : {},
    };

    const { data, headers } = await storyblokApi.get('cdn/stories/', {
        starts_with: 'news/news-article/',
        version: 'published',
        page: currentPage.value,
        per_page: pageSize.value,
        filter_query: filterQuery,
        sort_by: 'first_published_at:desc',
        first_published_at_gt: !dates.value || dates.value.length === 0 ? null : `${dates.value.toString()}-01-01 00:01`,
        first_published_at_lt: !dates.value || dates.value.length === 0 ? null : `${dates.value.toString()}-12-31 23:58`,
        resolve_relations: 'News.department,News.type,News.country',
        language,
    });

    if (currentPage.value === 1) {
        news.value = data.stories;
        if (news.value.length < parseInt(headers?.total, 10)) {
            showLoadMore.value = true;
        } else {
            showLoadMore.value = false;
        }
        return;
    }

    news.value = [...news.value, ...data.stories];
    if (news.value.length === parseInt(headers?.total, 10)) {
        showLoadMore.value = false;
    }
};

const trimString = (str, maxlength) => {
    if (str?.length > maxlength) {
        const trimmedstr = `${str.substring(0, maxlength)}...`;
        return trimmedstr;
    }

    return str;
};

const loadMore = () => {
    currentPage.value += 1;
    getArticles();
};

onMounted(() => {
    getArticles();
});

watch(filterStore, () => {
    currentPage.value = 1;
    getArticles();
});

const colorTopLevel = inject('colorSchema');
const colorText = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
.block-news-filtered {
    display: flex;
    flex-direction: column;
}

.block-news-filtered__img {
    display: block;
    height: 300px;
    background-position: center center;
    object-fit: cover;
    transition: all 0.2s ease-out;
}

.block-news-filtered-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mobile {
        word-break: break-word;
    }
}

.block-news-filtered-wrapper::after {
    width: 30.4%;
    content: '';
}

.block-news-filtered__noArticles {
    margin-bottom: 40px;
    color: v-bind(colorText);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-news-filtered__teaser:first-of-type {
    position: relative;
    margin-bottom: 45px;

    &:hover {
        .block-news-filtered__img {
            transform: scale(1.05);
            transition: all 0.2s ease-out;
        }
        .block_teaser_video__tag-icon {
            transform: none;
        }
    }

    &.block-news-filtered--mini {
        display: flex;
        width: 100%;
        flex-direction: row;

        .block-news-filtered__img {
            width: 400px;
            height: 400px;

            @include mobile {
                width: 100%;
            }
        }

        .block-news-filtered__wrapper {
            display: flex;
            flex-basis: 55%;
            margin-left: 52px;

            .block-news-filtered__title-large {
                display: block;
                margin-top: 15px;
                color: v-bind(colorText);
                font-family: var(--f-family--bold);
                font-size: var(--f-size--h2);
                line-height: var(--l-height--h2);
            }

            .block-news-filtered__title {
                display: none;
            }

            .block-news-filtered__teaser-large {
                display: block;
                margin-bottom: 20px;
            }

            @include tablet-portrait {
                margin-left: 0;
            }
        }

        @include tablet-portrait {
            flex-direction: column;
        }
    }

    @include tablet {
        grid-column: 3 / span 14;
    }

    @include mobile {
        grid-column: 1 / span 3;
    }
}

.block-news-filtered__teaser {
    position: relative;
    display: flex;
    margin-bottom: 45px;

    &:hover {
        .block-news-filtered__img {
            transform: scale(1.05);
        }
        .block_teaser_video__tag-icon {
            transform: none;
        }
        .block-news-filtered__icon {
            transform: translateX(10px);
        }
    }

    &.block-news-filtered--mini {
        width: 30.4%;
        flex-direction: column;

        .block-news-filtered__img {
            @include mobile {
                width: 100%;
            }
        }

        .block-news-filtered__wrapper {
            display: flex;
            flex-direction: column;

            .block-news-filtered__date {
                margin-top: 10.87px;
            }

            .block-news-filtered__title {
                @include line-clamp(2);

                display: -webkit-box;
                overflow: hidden;
                margin-top: 10px;
                margin-bottom: 20px;
                -webkit-box-orient: vertical;
                color: v-bind(colorText);
                font-family: var(--f-family--bold);
                font-size: var(--f-size--h4);
                line-height: var(--l-height--h4);
                text-overflow: ellipsis;
            }

            .block-news-filtered__title-large {
                display: none;
            }

            .block-news-filtered__teaser-large {
                display: none;
            }
        }

        @include tablet-portrait {
            width: 47%;
        }

        @include mobile {
            width: 100%;
        }
    }

    @include tablet {
        grid-column: 3 / span 14;
    }

    @include mobile {
        grid-column: 1 / span 3;
    }
}

.block-news-filtered__imgWrapper {
    position: relative;
    overflow: hidden;
    width: fit-content;

    .block-news-filtered__tag {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        width: 125px;
        height: 50px;
        align-items: center;
        justify-content: center;
        background-color: v-bind(colorText);
        color: var(--c-white);
        font-size: 14px;
        opacity: 0.9;
    }

    .block-news-filtered__video-tag {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 105px;
        height: 50px;
        align-items: center;
        justify-content: space-around;
        background-color: v-bind(colorText);
        color: var(--c-white);
        font-size: 14px;
        opacity: 0.9;
    }
}

.block-news-filtered__date {
    margin-top: 20px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    word-wrap: unset;

}

.block-news-filtered_article {
    color: v-bind(colorText);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
}

.block-news-filtered__icon {
    color: var(--c-primary);
    font-size: 14px;
    transition: all 0.2s ease-out;

    @include tablet-portrait {
        position: relative;
    }
}

.block-news-filtered__more-button {
    max-width: 240px;
    align-self: end;
    margin-bottom: 50px;

    &.isHidden {
        display: none;
    }
}

.block-news-filtered__lead {
    color: v-bind(colorText);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}
</style>
