<template>
  <main class="main-content">
    <component
      :is="useComponent(item.component)"
      v-for="item of data"
      v-bind="buildProps(item)"
      :key="item._uid"
      v-editable="item"
      :class="[rtl ? 'is-rtl-section' : '']"
    />
  </main>
</template>

<script setup>
defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    rtl: {
        type: Boolean,
        default: false,
    },
});

const buildProps = (data) => {
    const itemProps = { ...data };
    delete itemProps.component;
    delete itemProps._uid;
    return itemProps;
};

/*
  Add more components here if you need them in the storybook render loop
*/
const availableComponents = {
    StoryblokTextHeading: resolveComponent('StoryblokTextHeading'),
    StoryblokImage: resolveComponent('StoryblokImage'),
    StoryblokTextRichtext: resolveComponent('StoryblokTextRichtext'),
    StoryblokIntroText: resolveComponent('StoryblokIntroText'),
    StoryblokHero: resolveComponent('StoryblokHero'),
    StoryblokLink: resolveComponent('StoryblokLink'),
    StoryblokVideo: resolveComponent('StoryblokVideo'),
    StoryblokVideoYoutube: resolveComponent('StoryblokVideoYoutube'),
    StoryblokQuote: resolveComponent('StoryblokQuote'),
    StoryblokAccordion: resolveComponent('StoryblokAccordion'),
    StoryblokTeaserCard: resolveComponent('StoryblokTeaserCard'),
    StoryblokIframe: resolveComponent('StoryblokIframe'),
    StoryblokProfile: resolveComponent('StoryblokProfile'),
    StoryblokInfoBox: resolveComponent('StoryblokInfoBox'),
    StoryblokImageGallery: resolveComponent('StoryblokImageGallery'),
    StoryblokSliderHero: resolveComponent('StoryblokSliderHero'),
    StoryblokTeaserPdf: resolveComponent('StoryblokTeaserPdf'),
    StoryblokKeyFigures: resolveComponent('StoryblokKeyFigures'),
    StoryblokTeaser: resolveComponent('StoryblokTeaser'),
    StoryblokTeaserCurated: resolveComponent('StoryblokTeaserCurated'),
    StoryblokOverlay: resolveComponent('StoryblokOverlay'),
    StoryblokSliderEnumeration: resolveComponent('StoryblokSliderEnumeration'),
    StoryblokNavigationSide: resolveComponent('StoryblokNavigationSide'),
    StoryblokTeaserDepartments: resolveComponent('StoryblokTeaserDepartments'),
    StoryblokTeaserNewsRegional: resolveComponent('StoryblokTeaserNewsRegional'),
    StoryblokTeaserNewsInternational: resolveComponent('StoryblokTeaserNewsInternational'),
    StoryblokTeaserBox: resolveComponent('StoryblokTeaserBox'),
    StoryblokFormContact: resolveComponent('StoryblokFormContact'),
    StoryblokInputCheckbox: resolveComponent('StoryblokInputCheckbox'),
    StoryblokInputMultiselect: resolveComponent('StoryblokInputMultiselect'),
    StoryblokInputRadio: resolveComponent('StoryblokInputRadio'),
    StoryblokInputText: resolveComponent('StoryblokInputText'),
    StoryblokInputTextarea: resolveComponent('StoryblokInputTextarea'),
    StoryblokTeaserProgramm: resolveComponent('StoryblokTeaserProgramm'),
    StoryblokSliderParticipation: resolveComponent('StoryblokSliderParticipation'),
    StoryblokKeyFiguresInternational: resolveComponent('StoryblokKeyFiguresInternational'),
    StoryblokTeaserSublinks: resolveComponent('StoryblokTeaserSublinks'),
    StoryblokTeaserSublinksScalable: resolveComponent('StoryblokTeaserSublinks'),
    StoryblokInfoProgramm: resolveComponent('StoryblokInfoProgramm'),
    StoryblokCompass: resolveComponent('StoryblokCompass'),
    StoryblokTagCloud: resolveComponent('StoryblokTagCloud'),
    StoryblokFilter: resolveComponent('StoryblokFilter'),
    StoryblokNewsFiltered: resolveComponent('StoryblokNewsFiltered'),
    StoryblokEmployeesBlock: resolveComponent('StoryblokEmployeesBlock'),
    StoryblokActivitiesFiltered: resolveComponent('StoryblokActivitiesFiltered'),
    BlockParticipationProcessSite: resolveComponent('BlockParticipationProcessSite'),
    StoryblokMapInternational: resolveComponent('StoryblokMapInternational'),
    StoryblokDownloadHub: resolveComponent('StoryblokDownloadHub'),
    StoryblokDownloadHubSmall: resolveComponent('StoryblokDownloadHubSmall'),
    StoryblokDownloadHubMini: resolveComponent('StoryblokDownloadHubMini'),
    StoryblokTeaserProjects: resolveComponent('StoryblokTeaserProjects'),
    StoryblokProjectsBlock: resolveComponent('StoryblokProjectsBlock'),
    StoryblokContributionCalculator: resolveComponent('StoryblokContributionCalculator'),
    StoryblokNewsletter: resolveComponent('StoryblokNewsletter'),
    StoryblokBreadcrumbs: resolveComponent('StoryblokBreadcrumbs'),
    StoryblokColorPalette: resolveComponent('StoryblokColorPalette'),
    StoryblokCustomCode: resolveComponent('StoryblokCustomCode'),
    BlockImplementationWheel: resolveComponent('BlockImplementationWheel'),
};

const useComponent = (key) => availableComponents[key] || null;

</script>
