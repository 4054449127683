<template>
  <div
    class="block-item-card__popup-card"
    :class="[`is-popup-card-${hoverId}`]"
  >
    <AtomImage
      v-if="image?.filename"
      class="block-item-card__popup-card__image"
      :image="image"
      provider="storyblok"
    />
    <div class="block-item-card__popup-card__content">
      <div class="block-item-card__popup-card__heading">
        <h4 v-if="title" class="block-item-card__popup-card__title">
          {{ title }}
        </h4>
        <p v-if="lead" class="block-item-card__popup-card__lead">
          {{ lead }}
        </p>
      </div>
      <p v-if="text" class="block-item-card__popup-card__text">
        {{ trimString(text, 220) }}
      </p>
      <div>
        <UtilRouteLink
          v-if="link?.id || link?.url"
          class="block-item-card__link"
          :link="link.cached_url"
        >
          <AtomButton
            class="block-item-card__button"
            :text="linkLabel"
            :color-schema="color ? color : 'petrol'"
            html-tag="div"
          />
        </UtilRouteLink>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    lead: {
        type: String,
        default: '',
    },
    text: {
        type: String,
        default: '',
    },
    color: {
        type: String,
        default: '',
    },
    linkLabel: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    image: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
    link: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
    hoverId: {
        type: Number,
        default: 0,
    },
});

const colorTopLevel = inject('colorSchema');
const colorBackground = computed(() => (props.color
    ? `var(--c-${props.color}-11)`
    : `var(--c-${colorTopLevel.value}-11)`));
const colorText = computed(() => (props.color
    ? `var(--c-${props.color}-1)`
    : `var(--c-${colorTopLevel.value}-1)`));

const trimString = (str, maxlength) => {
    if (str.length > maxlength) {
        const trimmedstr = `${str.substring(0, maxlength)}...`;
        return trimmedstr;
    }
    return str;
};

</script>
  <style lang="scss" scoped>
.block-item-card__popup-card {
    background: v-bind(colorBackground);

    &.is-popup-card-1,
    &.is-popup-card-2,
    &.is-popup-card-3 {
        @include fluid('width', 200px, 660px);
        @include z-index('lightbox');

        position: absolute;
        top: 50%;

        @include mobile {
            width: 70px;
        }

        @media screen and (min-width: 1800px) {
            width: 660px;
        }
    }

    &.is-popup-card-1 {
        left: 25%;
        transform: translate(-50%, -50%);
    }

    &.is-popup-card-2 {
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.is-popup-card-3 {
        right: 25%;
        transform: translate(50%, -50%);
    }
}

.block-item-card__popup-card__image {
    width: 100%;
    aspect-ratio: 16/9;
}

.block-item-card__popup-card__content {
    @include fluid('padding', 20px, 40px);
    @include fluid('gap', 20px, 30px);

    display: flex;
    flex-direction: column;
    color: v-bind(colorText);
}

.block-item-card__popup-card__text {
    font-family: var(--f-family--thin);
    font-size: var(--f-size--description);
    line-height: var(--l-height--description);

    @include mobile {
        padding-bottom: 20px;
    }
}

.block-item-card__popup-card__title {
    font-family: var(--f-family--black);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);
}

.block-item-card__popup-card__lead {
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
}
  </style>
