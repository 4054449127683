<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-filter is-narrow">
        <BlockFilter
          :title="title"
          :dropdowns="mappedDropdowns"
          :color="color"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    dropdowns: {
        type: Array,
        required: true,
    },

    color: {
        type: String,
        default: '',
    },
});

const {
    dropdowns,
} = toRefs(props);

const mappedDropdowns = ref([]);
const storyblokApi = useStoryblokApi();
const { dayjs } = useDayJs();
const now = dayjs().format('YYYY');
const years = ref([]);

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const getStoryblokData = async (option) => {
    if (option.options === 'date') {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'news/news-article/',
            version: 'published',
            per_page: 1,
            sort_by: 'first_published_at:asc',
            language,
        });

        const firstNewsArticle = data.stories[0];
        years.value = Array.from(
            { length: now - dayjs(firstNewsArticle.first_published_at).format('YYYY') + 1 },
            (_, i) => ({
                name: now - i,
                value: now - i,
            }),
        );

        // delete where options is date
        mappedDropdowns.value = mappedDropdowns.value.filter(
            (dropdown) => dropdown.name !== 'date',
        );

        // map data to mappedDropdowns as one option foreach stories
        mappedDropdowns.value.push({
            label: option.label,
            name: option.options,
            options: years.value.map((year) => ({
                name: year.name,
                value: year.value,
                type: option.options,
            })),
        });
    } else {
        try {
            const { data } = await storyblokApi.get('cdn/stories/', {
                starts_with: `data-sources/${option.options}`,
                version: 'published',
                language,
            });

            // delete where options is not date
            mappedDropdowns.value = mappedDropdowns.value.filter(
                (dropdown) => dropdown.name !== option.options,
            );

            // map data to mappedDropdowns as one option foreach storie
            mappedDropdowns.value.push({
                label: option.label,
                name: option.options,
                options: data.stories.map((story) => ({
                    name: story.translated_slugs?.find(
                        (slug) => slug.lang === currentLocale.value,
                    )?.name || story.name,
                    value: story.uuid,
                    type: option.options,
                })),
            });
        } catch (e) {
            useSentryError(e);
        }
        // sort mappedDropdowns by initial order of dropdowns
        mappedDropdowns.value.sort((a, b) => {
            const aIndex = props.dropdowns.findIndex(
                (dropdown) => dropdown.label === a.label,
            );
            const bIndex = props.dropdowns.findIndex(
                (dropdown) => dropdown.label === b.label,
            );

            return aIndex - bIndex;
        });
    }
};

// get initial data
dropdowns.value.forEach((dropdown) => {
    getStoryblokData(dropdown);
});

watch(dropdowns, () => {
    mappedDropdowns.value = [];
    dropdowns.value.forEach((dropdown) => {
        getStoryblokData(dropdown);
    });
});

</script>

  <style lang="scss">
    .storyblok-filter {
        @include default-content-columns;

        margin-bottom: 5px;
    }
  </style>
