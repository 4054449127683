<template>
  <div class="block-item-calculation-person-block">
    <div class="block-item-calculation-person-block__info">
      <p class="block-item-calculation-person-block__title">
        {{ useStoryblokTranslation('general', 'your_contact') }}
      </p>

      <div class="block-item-calculation-person-block__contact-info">
        <p v-if="dataContact?.department" class="block-item-calculation-person-block__department">
          {{ dataContact?.department }}
        </p>
        <p v-if="dataContact?.name" class="block-item-calculation-person-block__name">
          {{ dataContact?.name }}
        </p>
        <p v-if="dataContact?.position && dataContact?.position.length > 0" class="block-item-calculation-person-block__position">
          {{ dataContact?.position }}
        </p>
      </div>
    </div>
    <AtomImage
      v-if="dataContact?.image?.filename"
      class="block-item-calculation-person-block__image"
      :image="dataContact?.image"
      provider="storyblok"
      lazy="false"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    pretitle: {
        type: String,
        default: '',
    },
    title: {
        type: String,
        default: '',
    },
    data: {
        type: Object,
        default: () => {},
    },
    colorDark: {
        type: String,
        default: '',
    },
    colorLight: {
        type: String,
        default: '',
    },
});

// watch data for changes to set header

const dataContactUID = computed(() => props.data.person);
const dataContact = ref([]);

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const storyblokApi = useStoryblokApi();
const getContact = async (uuids) => {
    if (uuids.length === 0) return;
    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'data-sources/people',
            version: 'published',
            by_uuids: uuids,
            language,
        });
        const [person] = data.stories;
        dataContact.value = person?.content;
    } catch (e) {
        useSentryError(e);
    }
};
onMounted(async () => {
    await getContact(dataContactUID.value);
});

</script>
<style lang="scss">
.block-item-calculation-person-block {
    display: flex;
    background: transparent;
    @include mobile {
        grid-column-end: span 2;
    }
}
.block-item-calculation-person-block__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 30px;
    margin-right: 3px;
    background: v-bind(colorLight);
    color: v-bind(colorDark);
    gap: 20px;
    @include mobile {
        padding: 20px;
        margin-right: 0px;
        gap: 0px;
    }
}
.block-item-calculation-person-block__title {
    font-family: var(--f-family--black);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
    @include mobile {
        padding-bottom: 5px;
        font-family: var(--f-family--regular);
    }

}
.block-item-calculation-person-block__image {
    flex-basis: 32%;
    flex-shrink: 0;
    @include tablet {
        flex-basis: 0;
    }
}
.block-item-calculation-person-block__department {
    padding-bottom: 5px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
    @include mobile {
        padding-bottom: 0px;
    }
}
.block-item-calculation-person-block__name {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    @include mobile {
        padding-bottom: 5px;
    }
}
.block-item-calculation-person-block__position {
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}
</style>
