<template>
  <div class="storyblok-compass">
    <AtomImage
      v-if="iconUrl?.filename"
      class="storyblok-compass__icon"
      :image="iconUrl"
      provider="storyblok"
      width="360px"
      height="150px"
    />
    <UtilWrapperLayout class="storyblok-compass__wrapper">
      <UtilGrid>
        <div class="storyblok-compass__navigation">
          <div class="storyblok-compass__navigation-items">
            <div
              v-for="(item, index) of navigationDataItems"
              :key="item._uid"
              class="storyblok-compass__navigation__item"
              @click="setItems(item)"
              @keypress="setItems(item)"
            >
              <div
                :class="[`is-separator-${index + 1}`]"
                class="storyblok-compass__navigation__separator"
              />
              <div
                class="storyblok-compass__navigation__item__text"
                :class="{
                  'is-last': navigationDataItems.length - 1 === index,
                }"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </UtilGrid>
      <UtilGrid>
        <div v-if="showCardsSection" class="storyblok-compass__cards is-extra-wide">
          <component
            :is="useComponent(item.component)"
            v-for="item of currentDataItems"
            v-bind="item"
            :key="item._uid"
            :global-color="color"
            :secondary-icon="iconSmall"
            @pass-items-data="getChildItems"
          />
        </div>
        <div v-if="!showCardsSection" class="storyblok-compass__swiper__section is-extra-wide">
          <Swiper
            v-if="items && items.length > 0"
            class="storyblok-compass__swiper"
            :direction="'horizontal'"
            :pagination="{
              type: 'fraction',
              el: '.swiper-pagination',
            }"
            :navigation="{
              nextEl: '.storyblok-slider-participation__controls--next',
              prevEl: '.storyblok-slider-participation__controls--prev',
            }"
            :grab-cursor="true"
            :modules="modules"
            :space-between="30"
            :slides-per-view="isTablet ? 'auto' : 4"
            @swiper="onSwiper($event)"
          >
            <SwiperSlide
              v-for="(item, index) in currentDataItems"
              :key="index"
              class="storyblok-compass__swiper-item"
            >
              <component
                :is="useComponent(item.component)"
                v-bind="item"
                :key="item._uid"
                class="storyblok-compass__swiper-card"
                :global-color="color"
                :secondary-icon="iconSmall"
                @pass-items-data="getChildItems"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </UtilGrid>
      <UtilGrid>
        <div class="storyblok-compass__back-btn">
          <AtomButton
            v-if="navigationDataItemIndex"
            :text=" useStoryblokTranslation('general', 'back')"
            :color-code="colorBtn"
            :color-code-hover="colorBtnHover"
            class="storyblok-compass__btn"
            @click="getPreviousData"
            @keypress="getPreviousData"
          />
        </div>
        <div v-if="!showCardsSection " class="storyblok-slider-participation__mobile__controls">
          <div class="storyblok-slider-participation__control storyblok-slider-participation__controls--prev">
            <div class="storyblok-slider-participation__controls">
              <IonIcon icon-name="chevron-right" class="storyblok-slider-participation__button-icon left" />
            </div>
          </div>
          <div class="swiper-pagination storyblok-slider-participation__swiper__pagination" />
          <div class="storyblok-slider-participation__control storyblok-slider-participation__controls--next">
            <div class="storyblok-slider-participation__controls">
              <IonIcon icon-name="chevron-left" class="storyblok-slider-participation__button-icon right" />
            </div>
          </div>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>

const props = defineProps({
    title: {
        type: String,
        required: true,
    },

    items: {
        type: Array,
        default: () => [],
        validator: (value) => value.length > 0,
    },

    image: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },

    icon: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },

    colorGlobal: {
        type: String,
        default: 'petrol',
    },

    selectedStep: {
        type: String,
        default: '',
    },
});
// import Swiper core and required modules
const {
    Navigation,
    Virtual,
    Pagination,
    Autoplay,
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const modules = [Virtual, Navigation, Pagination, Autoplay];
const clientStore = useClientDeviceStore();
const isTablet = computed(() => (clientStore.isTablet));
const currentDataItems = ref([]);
const navigationIcon = ref('');
const iconSmall = ref('house');
const swiper = ref(null);
const navigationDataItems = ref([]);
const navigationDataItemIndex = ref(props.selectedStep !== '' ? 1 : 0);
const color = ref('petrol');
const selectedStepIndex = computed(() => props.selectedStep !== '' && Number(props.selectedStep) - 1);
const colorBackgroundSection = computed(() => (color.value ? `var(--c-${color.value}-6)` : 'var(--c-petrol-6)'));
const colorBtn = computed(() => (color.value ? `var(--c-${color.value}-4)` : 'var(--c-petrol-4)'));
const colorBtnHover = computed(() => (color.value ? `var(--c-${color.value}-5)` : 'var(--c-petrol-5)'));
const showCardsSection = computed(() => currentDataItems.value.length <= 4 && !isTablet.value);

const onSwiper = (e) => {
    swiper.value = e;
};

watchEffect(() => {
    navigationDataItems.value = [];
    navigationDataItems.value.push({
        items: props.items,
        title: props.title,
        color: props.colorGlobal,
    });

    if (selectedStepIndex.value !== false) {
        currentDataItems.value = props.items[selectedStepIndex.value].items;
        navigationDataItems.value.push({
            items: props.items[selectedStepIndex.value].items,
            title: props.items[selectedStepIndex.value].title,
            color: props.items[selectedStepIndex.value].color,
        });

        color.value = props.items[selectedStepIndex.value].color;
        navigationIcon.value = props.items[selectedStepIndex.value].icon;
        iconSmall.value = props.items[selectedStepIndex.value].iconSmall;
    } else {
        currentDataItems.value = props.items;
        navigationIcon.value = props.icon;
    }
});

const iconUrl = computed(() => navigationIcon.value);

const getChildItems = (data) => {
    iconSmall.value = data?.iconSmall;
    currentDataItems.value = data.items;

    if (data.icon) {
        navigationIcon.value = data.icon;
    }

    if (data.color) {
        color.value = data.color;
    }

    navigationDataItems.value.push(data);
    navigationDataItemIndex.value += 1;
};

const setItems = (key) => {
    currentDataItems.value = key.items;
    iconSmall.value = key?.iconSmall;

    navigationDataItemIndex.value = navigationDataItems.value.findIndex(
        (obj) => obj.title === key.title,
    );

    navigationDataItems.value.splice(
        navigationDataItemIndex.value + 1,
        navigationDataItems.value.length - (navigationDataItemIndex.value + 1),
    );

    if (navigationDataItemIndex.value === 0) {
        navigationIcon.value = props.icon;
    }

    color.value = key.color;
};

const getPreviousData = () => {
    if (navigationDataItemIndex.value > 0 && navigationDataItems.value.length > 0) {
        navigationDataItems.value.splice(navigationDataItemIndex.value, 1);
        navigationDataItemIndex.value -= 1;

        currentDataItems.value = navigationDataItems.value[navigationDataItemIndex.value].items;
    }

    if (navigationDataItemIndex.value === 0) {
        navigationIcon.value = props.icon;
    }

    if (navigationDataItems.value.length > 0) {
        color.value = navigationDataItems.value[navigationDataItemIndex.value].color;
    }
};

const availableComponents = {
    BlockItemNavigationCard: resolveComponent('BlockItemNavigationCard'),
    BlockItemProjectCard: resolveComponent('BlockItemProjectCard'),
    BlockItemContactCard: resolveComponent('BlockItemContactCard'),
    BlockItemDetailCard: resolveComponent('BlockItemDetailCard'),
};

const useComponent = (key) => availableComponents[key] || null;

/*
    Watch for changes in the currentDataItems and slide to the first slide
*/
watch(currentDataItems, () => {
    if (swiper.value) {
        swiper.value.slideTo(0);
    }
});

</script>
<style lang="scss">
.storyblok-compass {
    @include fluid('margin-bottom', 50px, 80px);
    // Leave this comment here until designreview is done
    // @include fluid('height', 644px, 1073px);

    position: relative;
    width: 100%;
    height: 100vh;
    padding: 48px 0;
    background: v-bind(colorBackgroundSection);
    background-repeat: no-repeat;
    background-size: cover;
    color: v-bind(colorText);

    @include tablet {
        padding: 26px 0 20px 0;
    }
}

.storyblok-compass__wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.storyblok-compass__navigation {
    width: 100%;
    cursor: pointer;
    grid-column-end: span var(--grid-columns);
    grid-column-start: 1;

    @include tablet {
        position: relative;
        overflow: hidden;
        height: 40px;
    }
}

.storyblok-compass__navigation-items {
    display: flex;
    width: 100%;
    cursor: pointer;
    grid-column-end: span var(--grid-columns);
    grid-column-start: 1;

    @include tablet {
        position: absolute;
        display: inline-flex;
        display: flex;
        width: 100% !important;
        height: 100%;
        padding-right: 30px;
        overflow-x: scroll;
        white-space: nowrap;
        @include remove-scrollbars;
    }
}

.storyblok-compass__navigation__item {
    display: flex;
    align-items: center;
}

.storyblok-compass__navigation__item__text {
    color: var(--c-white);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);

    &.is-last {
        font-family: var(--f-family--bold);
    }

    @include tablet {
        font-size: var(--f-size--a);
        line-height: var(--l-height--a);
    }
}

.storyblok-compass__navigation__separator {
    width: 1.5px;
    height: 38px;
    margin: 0 20px;
    background: var(--c-white);

    &.is-separator-1 {
        display: none;
    }

    @include tablet {
        height: 29px;
        margin: 0 22px;
    }
}

.storyblok-compass__cards {
    @include default-content-columns;

    display: grid;
    justify-content: center;
    column-gap: var(--grid-gutter);
    grid-auto-columns: 23%;
    grid-auto-flow: column;

    @include tablet {
        column-gap: 20px;
    }
}

.storyblok-compass__back-btn {
    position: relative;
    grid-column-end: span 16;
    grid-column-start: 2;

    @include mobile {
        flex-direction: column;
        grid-column-end: span 3;
        grid-column-start: 1;
    }
}

.storyblok-compass__btn {
    display: block;
}

.storyblok-compass__swiper__section {
    grid-column-end: span var(--grid-columns);
    grid-column-start: 1;

    @include mobile {
        min-height: auto;
        grid-column-end: span var(--grid-columns);
        grid-column-start: 1;
    }

    .swiper-slide {
        height: auto;
        .project-card {
            height: 100%;
            min-height: 420px;

            @include mobile {
                min-height: 380px;
            }
        }
    }
}

.storyblok-compass__swiper-item {
    @include tablet {
        width: 360px !important;
    }

    @include mobile {
        width: 270px !important;
    }
}

.storyblok-compass__swiper-card {
    @include tablet {
        height: 100%;
    }
}

.storyblok-slider-participation__mobile__controls {
    @include z-index('main');

    position: static;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column: 9 / span 3 ;

    @include tablet {
        position: relative !important;
        bottom: 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column-end: span 3;
        grid-column-start: 1;

    }
}

.storyblok-slider-participation__control {
    display: flex;
    color: v-bind(colorText);
    cursor: pointer;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    outline: none;

    path {
        stroke: var(--c-white);
    }

    .storyblok-slider-participation__controls {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            margin-right: 27px;
            margin-left: 27px;
            transform: rotate(-180deg);
        }
    }

    &.storyblok-slider-participation__controls--next {
        &.is-hidden {
            display: none;
        }
    }

    &.storyblok-slider-participation__controls--prev {
        left: 37%;
        &.is-hidden {
            display: none;
        }
    }
}

.storyblok-slider-participation__swiper__pagination {
    position: relative !important;
    bottom: 0 !important;
    display: flex !important;
    max-width: 50px;
    color: var(--c-white);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
}

.storyblok-compass__icon {
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 68.5%;

    @include mobile {
        height: 35.7%;
    }
}
</style>
