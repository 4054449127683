<template>
  <div
    class="project-card"
    :class="{ 'has-direct-link': hasDirectLink, 'has-next-button': buttonLabel }"
    @click="emitFunction"
    @keypress="emitFunction"
  >
    <AtomImage
      v-if="image?.filename"
      class="project-card__image"
      :image="image"
      provider="storyblok"
      width="430px"
      height="190px"
    />
    <div class="project-card__content">
      <IonIcon v-if="showIcon" class="project-card__icon" :icon-name="iconName" />
      <div class="project-card__heading">
        <h4 v-if="title" class="project-card__title">
          {{ title }}
        </h4>
      </div>
      <p v-if="text" class="project-card__text">
        {{ text }}
      </p>
      <div>
        <UtilRouteLink
          v-if="link?.id || link?.url || linkDirect?.cached_url"
          class="block-item-card__link"
          :link="link?.cached_url || linkDirect?.cached_url"
        >
          <AtomButton
            class="block-item-card__button"
            :text="linkDirectLabel"
            :color-schema="globalColor ? globalColor : 'petrol'"
            html-tag="div"
          />
        </UtilRouteLink>
        <AtomButton
          v-if="buttonLabel && !linkDirect?.cached_url"
          class="block-item-card__button"
          :text="buttonLabel"
          :color-schema="globalColor ? globalColor : 'petrol'"
          html-tag="div"
          variant="real-outline"
          @click="emitFunction"
          @keypress="emitFunction"
        />
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
    title: {
        type: String,
        required: true,
    },

    text: {
        type: String,
        default: '',
    },

    globalColor: {
        type: String,
        default: '',
    },

    image: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },

    showIcon: {
        type: Boolean,
        default: false,
    },

    items: {
        type: Array,
        default: () => [],
    },

    secondaryIcon: {
        type: String,
        default: 'house',
    },

    link: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },

    linkDirect: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },

    linkDirectLabel: {
        type: String,
        default: '',
    },

    buttonLabel: {
        type: String,
        default: '',
    },
});

const hasDirectLink = computed(() => props.linkDirect?.cached_url);
const emit = defineEmits(['passItemsData']);
const emitFunction = () => {
    if (hasDirectLink.value) return;

    emit('passItemsData', {
        title: props.title,
        items: props.items,
        color: props.globalColor,
        iconSmall: props.secondaryIcon,
    });
};

const iconName = computed(() => (props.secondaryIcon ? `internal-${props.secondaryIcon}` : 'internal-house'));
const colorTopLevel = inject('colorSchema');

const colorBackground = computed(() => (props.globalColor
    ? `var(--c-${props.globalColor}-11)`
    : `var(--c-${colorTopLevel.value}-11)`));

const colorIcon = computed(() => (props.globalColor
    ? `var(--c-${props.globalColor}-8)`
    : `var(--c-${colorTopLevel.value}-8)`));

const colorText = computed(() => (props.globalColor
    ? `var(--c-${props.globalColor}-1)`
    : `var(--c-${colorTopLevel.value}-1)`));

</script>
<style lang="scss">
.project-card {
    background: v-bind(colorBackground);
    cursor: pointer;
    grid-column-end: span 1;

    &.has-direct-link {
        cursor: default;
    }

    &.has-next-button {
        cursor: default;
    }
}

.project-card__image {
    @include fluid('height', 112px, 154px);
}

.project-card__content {
    @include fluid('padding', 20px, 40px);
    @include fluid('padding-top', 20px, 20px);
    @include fluid('gap', 20px, 20px);

    display: flex;
    flex-direction: column;
    color: v-bind(colorText);
}

.project-card__text {
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    @include line-clamp(3);
    @include mobile {
        padding-bottom: 20px;
    }
}

.project-card__title {
    font-family: var(--f-family--black);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);
    @include word-break;
}

.project-card__icon {
    width: 36px !important;
    height: 33px !important;
    .dark-icon-color {
        fill: v-bind(colorIcon);
    }
}
</style>
