<template>
  <div class="storyblok-navigation-side">
    <div class="storyblok-navigation-side__list-outer">
      <div class="storyblok-navigation-side__navigation" :class="{ 'is-open': isOpen }">
        <div
          ref="navigationWrapper"
          class="storyblok-navigation-side__navigation__wrapper"
        >
          <button
            v-for="(item, index) in items"
            :key="index"
            type="button"
            class="storyblok-navigation-side__navigation-item"
            :class="{ 'is-active': isActive === (index + 1) }"
            @click="scrollTo(index + 1)"
            @keypress="scrollTo(index + 1)"
          >
            {{ item.title }}
          </button>
        </div>
        <div class="storyblok-navigation-side__navigation-icon" @click="toggleOpen" @keypress="toggleOpen">
          <ClientOnly>
            <IonIcon v-if="isOpen " :icon-name="isTablet ? 'close' : 'chevron-left'" />
            <IonIcon v-else icon-name="menu-burger" />
          </ClientOnly>
        </div>
      </div>
    </div>
    <div class="storyblok-navigation-side__item-container">
      <BlockNavigationSideItem
        v-for="item, index in items"
        :id="'element-' + (index + 1)"
        :key="item._uid"
        class="storyblok-navigation-side__item"
        :block="item"
      />
    </div>
  </div>
</template>

<script setup>
defineProps({
    items: {
        type: Array,
        required: true,
        default: () => [],
    },
});

const clientStore = useClientDeviceStore();
const { isTablet } = storeToRefs(clientStore);
const isOpen = ref(false);
const isActive = ref(null);
const gsapActive = ref(false);
const navigationWrapper = ref(null);
const navigationWrapperWidth = ref(0);

const colorTopLevel = inject('colorSchema');
const colorText = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const colorIcon = computed(() => `var(--c-${colorTopLevel.value}-4)`);

const toggleOpen = () => {
    isOpen.value = !isOpen.value;
};

const checkActive = () => {
    const elements = document.querySelectorAll('.storyblok-navigation-side__item');

    elements.forEach((element, index) => {
        const elementTop = element.getBoundingClientRect().top;
        const elementBottom = element.getBoundingClientRect().bottom;

        if (elementTop <= 200 && elementBottom >= 100) {
            isActive.value = (index + 1);
        }
    });
};

onMounted(() => {
    if (!gsapActive.value) {
        window.addEventListener('scroll', () => {
            setTimeout(() => {
                checkActive();
            }, 100);
        }, {
            passive: true,
        });
    }

    navigationWrapperWidth.value = `-${navigationWrapper.value.offsetWidth}px`;

    window.addEventListener('resize', () => {
        navigationWrapperWidth.value = `-${navigationWrapper.value.offsetWidth}px`;
    });
});

const router = useRouter();

const scrollTo = (index) => {
    gsapActive.value = true;
    isActive.value = index;

    gsapScrollTo(window, {
        scrollTo: {
            y: `#element-${index}`,
        },
        duration: 0.2,
        onComplete: () => {
            gsapActive.value = false;
            router.push(`#element-${index}`);
        },
    });
};
</script>

<style lang="scss">
.storyblok-navigation-side {
    position: relative;
    grid-column-gap: 30px;
}

.storyblok-navigation-side__list-outer {
    position: absolute;
    width: 456px;
    height: 100%;

    @include mobile {
        width: 287px;
    }
}

.storyblok-navigation-side__navigation {
    @include z-index('lightbox');

    position: sticky;
    top: 125px;
    display: flex;
    font-family: var(--f-family--regular);
    transform: translateX(v-bind(navigationWrapperWidth));
    transition: transform 100ms $EASE_IN--QUAD;

    @include tablet {
        transform: translateX(-243px);
    }

    &.is-open {
        transform: translateX(0px);
    }
}

.storyblok-navigation-side__navigation__wrapper {
    @include z-index('lightbox');
    @include fluid('padding-top', 40px, 40px);
    @include fluid('padding-right', -20px, 40px);
    @include fluid('padding-bottom', 40px, 40px);
    @include fluid('padding-left', -20px, 80px);

    display: flex;
    flex-direction: column;
    background: var(--c-white);

    @include tablet {
        padding: 20px;
    }
}

.storyblok-navigation-side__navigation-icon {
    @include z-index('lightbox');

    display: flex;
    width: 76px;
    height: 76px;
    align-items: center;
    justify-content: center;
    background: var(--c-white);
    cursor: pointer;

    path {
        stroke: v-bind(colorIcon);
    }

    g {
        fill: v-bind(colorIcon);
    }

    @include tablet {
        width: 28px;
        height: 28px;
    }
}

.storyblok-navigation-side__navigation-item {
    position: relative;
    width: 260px;
    margin-bottom: 20px;
    color: v-bind(colorText);
    cursor: pointer;
    font-size: var(--f-size--side-nav);
    hyphens: auto;
    line-height: var(--l-height--side-nav);

    @include tablet {
        width: 203px;
    }

    button {
        line-height: 26px;
    }

    &.is-active {
        color: v-bind(colorText);
    }

    &::before {
        position: absolute;
        bottom: 0;
        width: 0;
        height: 1px;
        border-radius: 10px;
        content: '';
        transform: translateX(0px);
        transition: width 0.5s $EASE_OUT--QUAD;
    }

    &.is-active::before {
        position: absolute;
        bottom: 0;
        width: 32px;
        height: 1px;
        border-radius: 10px;
        background: v-bind(colorText);
        content: '';
        transition: all 100ms ease-in-out;
    }
}

.storyblok-navigation-side__item-container {
    scroll-behavior: smooth !important;
}

.storyblok-navigation-side__item {
    @include fluid('margin-bottom', 40px, 60px);
}

.storyblok-navigation-side__label {
    margin-bottom: 20px;

    @include tablet {
        display: none;
        padding-right: 25px;
        padding-left: 25px;
        margin-bottom: 10px;
    }
}
</style>
