<template>
  <div class="block-filter-activities">
    <div
      v-if="!activities.length"
      class="block-filter-activities__noArticles"
    >
      <AtomRichtext :html="parsedHtml" />
    </div>
    <div
      v-for="activitie, index in trimmedActivities"
      :id="'activity-' + index"
      :key="activitie.uuid"
      class="block-filter-activities__card"
    >
      <UtilRouteLink
        v-if="activitie.default_full_slug"
        :link="activitie.default_full_slug"
        @click="saveActivityIndex(index)"
        @keypress="saveActivityIndex(index)"
      >
        <div class="block-filter-activities__content">
          <div class="block-filter-activities__img-wrapper">
            <AtomImage
              v-if="activitie?.content?.image?.filename"
              class="block-filter-activities__img"
              :image="activitie?.content.image"
              width="580px"
              height="450px"
              provider="storyblok"
            />
          </div>
          <div class="block-filter-activities__text-section">
            <div class="block-filter-activities__country">
              {{ activitie?.content?.country?.name }}
            </div>
            <div class="block-filter-activities__title">
              {{ activitie.content.title }}
            </div>
            <IonIcon
              class="block-filter-activities__icon"
              icon-name="chevron-right"
            />
          </div>
        </div>
      </UtilRouteLink>
    </div>

    <AtomButton
      v-if="articles.length !== pageIndex && articles.length > 6"
      class="block-filter-activities__more-button"
      :text="buttonLabel"
      @click="loadMore()"
    />
    <div v-else class="block-filter-activities__btn-stub" />
  </div>
</template>

<script setup>
const props = defineProps({
    articles: {
        type: Array,
        default: () => [],
    },

    color: {
        type: String,
        default: '',
    },

    buttonLabel: {
        type: String,
        default: '',
    },
});

const { richTextResolver } = useStoryblokApi();
const activities = computed(() => (props.articles));
const parsedHtml = computed(() => richTextResolver.render(useStoryblokTranslation('general', 'no_activities', true)));
const activityStore = useActivityStore();
const pageIndex = computed(() => activityStore.getStoredPageIndex);
const savedIndex = computed(() => activityStore.getStoredIndex);
const currentLayout = computed(() => activityStore.getCurrentLayout);
const trimmedActivities = computed(() => activities.value.slice(0, pageIndex.value));

const loadMore = () => {
    activityStore.setStoredPageIndex(pageIndex.value + activities.value.length - 6);
};

// Scroll to saved index
const scrollToActivity = () => {
    gsapScrollTo(window, {
        scrollTo: {
            y: `#activity-${savedIndex.value}`,
            offsetY: 100,
        },
        duration: 0,
    });
};

const scrollToTop = () => {
    gsapScrollTo(window, {
        scrollTo: {
            y: 0,
        },
        duration: 0,
    });
};

onMounted(() => {
    setTimeout(() => {
        if (currentLayout.value === 'default') {
            scrollToActivity();
        }
    }, 200);
});

watch(currentLayout, () => {
    if (currentLayout.value === 'activity') {
        scrollToTop();
    }
});

const saveActivityIndex = (index) => {
    activityStore.setStoredIndex(index);
};

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const colorText1 = computed(() => (props.color ? `var(--c-${props.color}-1)` : 'var(--c-orange-1)'));
const colorText4 = computed(() => (props.color ? `var(--c-${props.color}-4)` : 'var(--c-orange-4)'));
</script>

<style lang="scss">
.block-filter-activities {
    @include grid-layout(2);

    font-family: var(--f-family--thin);
    row-gap: var(--grid-row--gap);
}

.block-filter-activities__card {
    grid-column-end: span 1;

    @include mobile {
        grid-column-start: span 2;
    }
}

.block-filter-activities__img {
    display: block;
    width: fit-content;
    height: 100%;
    background-position: center center;
    object-fit: cover;
    transition: all 0.2s ease-out;

    img {
        min-height: 419px;
        @include mobile {
            min-height: 204px;
        }
    }
}

.block-filter-activities__icon {
    width: 22px;
    height: 22px;
    color: v-bind(colorText1);
    transition: all 0.2s ease-out;

    path {
        stroke: v-bind(colorText1);
    }

    @include tablet-portrait {
        position: relative;
    }
}

.block-filter-activities__content {
    position: relative;
    display: flex;
    flex-direction: column;

    &:hover {
        .block-filter-activities__img {
            transform: scale(1.05);
        }

        .block_teaser_video__tag-icon {
            transform: none;
        }

        .block-filter-activities__icon {
            transform: translateX(10px);
        }
    }
}

.block-filter-activities__text-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0px 22px 40px;
    gap: 15px;

    @include mobile {
        word-break: break-word;
    }
}

.block-filter-activities-wrapper::after {
    width: 30.4%;
    content: '';
}

.block-filter-activities__noArticles {
    margin-bottom: 40px;
    color: v-bind(color1);
    font-family: var(--f-family--thin);
    grid-column-end: span 2;

}

.block-filter-activities__img-wrapper {
    position: relative;
    overflow: hidden;
}

.block-filter-activities__country {
    color: v-bind(colorText4);
    font-size: 17px;
    line-height: 27px;
}

.block-filter-activities__title {
    color: v-bind(colorText1);
    font-size: var(--f-size--programm-title);
    line-height: var(--l-height--programm-title);

    @include mobile {
        font-family: var(--f-family--regular);
    }
}

.block-filter-activities__more-button {
    margin-bottom: 50px;
    grid-column-start: 1;
    justify-self: start;

    &.isHidden {
        display: none;
    }
}

.block-filter-activities__btn-stub {
    height: 98px;
}
</style>
