<template>
  <div class="block-item-teaser-programm is-medium-wide">
    <div
      class="block-item-teaser-programm__card"
    >
      <AtomImage
        v-if="image?.filename"
        class="block-item-teaser-programm__image"
        :image="image"
        provider="storyblok"
      />
      <div class="block-item-teaser-programm__content">
        <h4 v-if="title" class="block-item-teaser-programm__title">
          {{ title }}
        </h4>
        <p v-if="text" class="block-item-teaser-programm__text">
          {{ text }}
        </p>
        <UtilRouteLink
          v-if="link?.id || link?.url"
          class="block-item-teaser-programm__link"
          :link="link?.cached_url"
        >
          <AtomButton
            class="block-item-teaser-programm__button"
            :text="linkLabel"
            :color-schema="color"
            html-tag="div"
          />
        </UtilRouteLink>
        <div class="block-item-teaser-programm__nav">
          <UtilRouteLink
            v-for="navLink in navLinks"
            :key="navLink?._uid"
            :link="navLink?.link?.cached_url"
            class="block-item-teaser-programm__navigation-item"
          >
            {{ navLink?.linkLabel }}
          </UtilRouteLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    link: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },

    linkLabel: {
        type: String,
        default: '',
    },

    color: {
        type: String,
        default: 'petrol',
    },

    navLinks: {
        type: Array,
        default: () => [],
    },

    image: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
});
const colorText = computed(() => `var(--c-${props.color}-1)`);

const colorBackground = computed(() => `var(--c-${props.color}-11)`);
</script>

<style lang="scss" scoped>
    .block-item-teaser-programm {
        position: relative;
        flex-grow: 2;
        background: v-bind(colorBackground);
    }
    .block-item-teaser-programm__image {
        aspect-ratio: 45/19;
    }

    .block-item-teaser-programm__content {
        display: flex;
        flex-direction: column;
        padding: 20px 40px;
        background: v-bind(colorBackground);
        color: v-bind(colorText);
        gap: 20px;
    }

    .block-item-teaser-programm__title {
        font-family: var(--f-family--black);
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);
    }

    .block-item-teaser-programm__text {
        max-width: 345px;
        padding-bottom: 20px;
        font-family: var(--f-family--thin);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    .block-item-teaser-programm__nav {
        display: flex;
        flex-direction: column;
    }

    .block-item-teaser-programm__navigation-item {
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        font-family: var(--f-family--thin);
        font-size: var(--f-size--side-nav);
        line-height: var(--l-height--side-nav);
        overflow-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-word;
        word-wrap: break-word;

        button {
            line-height: 26px;
        }

        &.is-active {
            color: var(--c-orange-1);
        }

        &::before {
            position: absolute;
            bottom: 0;
            width: 32px;
            height: 1px;
            border-radius: 10px;
            content: '';
            transform: translateX(0px);
            transition: background-color 100ms $EASE_IN--QUAD,transform 100ms $EASE_IN--QUAD;
        }

        &:hover, &:active {
            &::before {
                position: absolute;
                bottom: 0;
                width: 32px;
                height: 1px;
                border-radius: 10px;
                background: var(--c-orange-1);
                content: '';
                transition: all 100ms ease-in-out;
            }
        }
    }
</style>
