<template>
  <div class="block-breadcrumbs">
    <UtilWrapperLayout>
      <UtilGrid>
        <div v-if="route.fullPath != '/'" class="block-breadcrumbs__wrapper is-medium-wide">
          <div class="block-breadcrumbs__content">
            <nav class="block-breadcrumbs__crumbs">
              <ul class="block-breadcrumbs__items">
                <li v-for="(crumb, i) in breadcrumbs" :key="i" class="block-breadcrumbs__crumb-item">
                  <ClientOnly>
                    <nuxt-link v-if="crumb.path" :to="crumb.path">
                      <span
                        class="block-breadcrumbs__link"
                        :class="{
                          active: i === breadcrumbs.length - 1,
                          first: i === 0,
                        }"
                      >
                        {{ crumb.name }}
                      </span>
                    </nuxt-link>
                  </ClientOnly>
                  <span
                    class="block-breadcrumbs__separator"
                    :class="{
                      hide: i === breadcrumbs.length - 1,
                    }"
                  />
                </li>
              </ul>
            </nav>
          </div>
          <nuxt-link
            v-if="breadcrumbs?.length > 1"
            :to="breadcrumbs[breadcrumbs.length - 2].path"
          >
            <div class="block-breadcrumbs__back desktop">
              <IonIcon icon-name="chevron-left" class="block-breadcrumbs__icon" />
              <span class="block-breadcrumbs__back-title"> {{ useStoryblokTranslation('general', 'back_to') }}</span>
            </div>
          </nuxt-link>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
    <nuxt-link
      v-if="breadcrumbs?.length > 1"
      :to="breadcrumbs[breadcrumbs.length - 2].path"
    >
      <div class="block-breadcrumbs__back mobile">
        <IonIcon icon-name="chevron-left" class="block-breadcrumbs__icon" />
        <span class="block-breadcrumbs__back-title"> {{ useStoryblokTranslation('general', 'back_to') }}</span>
      </div>
    </nuxt-link>
  </div>
</template>

<script setup>
const props = defineProps({
    color: {
        type: String,
        default: '',
    },
});

const colorTopLevel = inject('colorSchema');
const colorText = computed(() => (props.color ? `var(--c-${props.color}-1)` : `var(--c-${colorTopLevel.value}-1)`));
const colorTextHover = computed(() => (props.color ? `var(--c-${props.color}-3)` : `var(--c-${colorTopLevel.value}-3)`));
const colorIcon = computed(() => (props.color ? `var(--c-${props.color}-4)` : `var(--c-${colorTopLevel.value}-4)`));

const { locale } = useI18n();
const route = useRoute();
const slugsStore = useSlugsStore();
const breadcrumbData = computed(() => slugsStore.getBreadcrumbs);

const slugs = {
    localized: route.fullPath?.endsWith('/') ? route.fullPath.substring(0, route.fullPath.length - 1) : route.fullPath,
};

// function to split slug
const splitSlug = (slug) => (slug.startsWith('/')
    ? slug.substring(1).split('/')
    : slug.split('/').filter((e) => e));

const convertWord = (arr) => arr.map((word) => word.substring(0)).join(' ');

const slug = ref('');

const breadcrumbs = computed(() => {
    slug.value = slugs.localized;

    const data = splitSlug(slug.value);
    let path = '';

    return data.map((param) => {
        path = `${path}/${param}`;

        const words = removeGetParams(param).split('-');
        return {
            path,
            name: convertWord(words),
        };
    });
});

breadcrumbData.value.forEach((item) => {
    if (!item.slug) return;

    breadcrumbs.value.forEach((crumb) => {
        if (crumb.path.includes(item.slug)) {
            if (!item.names) return;

            // if item.name in current language is not null replace the name in breadcrumbs
            if (item.names[locale.value]) {
                // eslint-disable-next-line no-param-reassign
                crumb.name = item.names[locale.value];
            } else {
                // eslint-disable-next-line no-param-reassign
                crumb.name = item.names.de;
            }
        }
    });
});

// remove item from breadcrumbs if path === locale
breadcrumbs.value.forEach((crumb, i) => {
    if (crumb.path === `/${locale.value}`) {
        breadcrumbs.value.splice(i, 1);
    }
});
</script>

<style lang="scss" scoped>
.block-breadcrumbs {
    position: relative;
    overflow: hidden;
    height: 100%;

    @media screen and (max-width: 1440px) {
        height: 126px;
    }
}
.block-breadcrumbs__wrapper {
    @include default-content-columns;

    width: 100%;
    padding: 40px 0;

    @media screen and (max-width: 1440px) {
        position: absolute;
        left: 0;
        display: inline-flex;
        display: flex;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        @include remove-scrollbars;
    }
}

.block-breadcrumbs__content {
    display: flex;
    align-items: center;
    padding-bottom: 26px;
    padding-left: var(--page-padding);
    color: v-bind(colorText);
}

.block-breadcrumbs__items {
    display: flex;
}

.block-breadcrumbs__crumb-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: v-bind(colorText);
    font-family: var(--f-family--regular);
    font-size: 28px;
    line-height: 38px;

    .active  {
        font-family: var(--f-family--black);

        @include mobile {
            padding-right: 30px;
        }
    }

    &:hover {
        color: v-bind(colorTextHover);
    }

    @media screen and (max-width: 1440px) {
        font-size: 14px;
        line-height: 22px;
    }
}

.block-breadcrumbs__link {
    padding: 6px 12px;

    &.first {
        padding-left: 0px;
    }
}

.block-breadcrumbs__separator {
    width: 2px;
    height: 38px;
    margin: 0 20px;
    background: v-bind(colorText);

    &.hide {
        display: none;
    }

    @media screen and (max-width: 1440px) {
        width: 1px;
        height: 29px;
        margin: 0 10px;
    }
}

.block-breadcrumbs__back {
    display: flex;
    align-items: center;
    color: v-bind(colorIcon);
    font-family: var(--f-family--thin);

    &.mobile {
        display: none;
    }

    @media screen and (max-width: 1440px) {
        position: absolute;
        z-index: 5;
        top: 92px;
        bottom: 30px;
        left: 53px;
        &.desktop {
            display: none;
        }
        &.mobile {
            display: flex;
        }
    }

    @include mobile {
        left: 33px;
    }
}

.block-breadcrumbs__icon {
    path {
        stroke: v-bind(colorIcon);
    }
}

.block-breadcrumbs__back-title {
    padding-left: 10px;
    font-size: 17px;
    line-height: 27px;

    @media screen and (max-width: 1440px) {
        font-size: 14px;
        line-height: 22px;
    }
}
</style>
