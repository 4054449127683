<template>
  <div
    class="detail-card"
  >
    <div class="detail-card__content">
      <div class="detail-card__heading">
        <h4 v-if="title" class="detail-card__title">
          {{ title }}
        </h4>
      </div>
      <p v-if="text" class="detail-card__text">
        {{ text }}
      </p>
      <div>
        <UtilRouteLink
          v-if="link?.cached_url"
          class="block-item-card__link"
          :link="link.cached_url"
        >
          <AtomButton
            class="block-item-card__button"
            :text="linkLabel"
            :color-schema="globalColor ? globalColor : 'petrol'"
            html-tag="div"
          />
        </UtilRouteLink>
      </div>
    </div>
    <AtomImage
      v-if="image?.filename"
      class="detail-card__image"
      :image="image"
      lazy="false"
      provider="storyblok"
    />
  </div>
</template>

<script setup>

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    text: {
        type: String,
        default: '',
    },
    color: {
        type: String,
        default: '',
    },
    image: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
    linkLabel: {
        type: String,
        default: '',
    },
    link: {
        type: Object,
        default: () => {},
    },
    globalColor: {
        type: String,
        default: '',
    },
});

const colorTopLevel = inject('colorSchema');
const colorBackground = computed(() => (props.globalColor
    ? `var(--c-${props.globalColor}-11)`
    : `var(--c-${colorTopLevel.value}-11)`));
const colorText = computed(() => (props.globalColor
    ? `var(--c-${props.globalColor}-1)`
    : `var(--c-${colorTopLevel.value}-1)`));

</script>
  <style lang="scss" scoped>
.detail-card {
    display: flex;
    gap: 3px;
    grid-column-end: span 2;
    @include tablet {
        flex-direction: column-reverse;
    }
}
.detail-card__image,
.detail-card__content {
    flex-basis: 50%;
}
.detail-card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: v-bind(colorBackground);
    color: v-bind(colorText);
    gap: 20px;
    @include fluid('padding', 20px, 40px);
}
.detail-card__text {
    @include line-clamp(3);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.detail-card__title {
    font-family: var(--f-family--black);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);
}

  </style>
