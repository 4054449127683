<template>
  <div class="block-item-sublinks-card">
    <div class="block-item-sublinks-card__content">
      <div class="block-item-sublinks-card__heading">
        <h4 v-if="title" class="block-item-sublinks-card__title">
          {{ title }}
        </h4>
      </div>
      <p v-if="text" class="block-item-sublinks-card__text">
        {{ text }}
      </p>
      <div>
        <UtilRouteLink
          v-if="link?.id || link?.url"
          class="block-item-card__link"
          :link="link.cached_url"
        >
          <AtomButton
            class="block-item-card__button"
            :text="linkLabel"
            :color-schema="color ? color : 'petrol'"
            variant="inverted"
            html-tag="div"
          />
        </UtilRouteLink>
      </div>
    </div>
    <div class="block-item-sublinks-card__overflow" />
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    lead: {
        type: String,
        default: '',
    },
    text: {
        type: String,
        default: '',
    },
    color: {
        type: String,
        default: '',
    },
    linkLabel: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    link: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
});

const colorTopLevel = inject('colorSchema');
const colorBackground = computed(() => (props.color
    ? `var(--c-${props.color}-7)`
    : `var(--c-${colorTopLevel.value}-7)`));

</script>
  <style lang="scss" scoped>
.block-item-sublinks-card {
    position: relative;
    background: v-bind(colorBackground);
    grid-column-end: span 2;
    @include mobile {
        grid-column-end: span 6;
    }
    @include above-desktop {
        &:hover .block-item-sublinks-card__overflow {
            opacity: 1;
            transform: scale(1.07);
        }
        &:hover .block-item-sublinks-card__content {
            transform: scale(1.07);
        }
    }
}
.block-item-sublinks-card__content {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    color: var(--c-white);
    @include fluid('padding', 20px, 40px);
    @include fluid('gap', 10px, 20px);
}
.block-item-sublinks-card__text {
    overflow: hidden;
    margin-right: 5px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    @include word-break;
    @include mobile {
        padding-bottom: 20px;
    }
}

.block-item-sublinks-card__title {
    width: 100%;
    font-family: var(--f-family--black);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);
    @include word-break;
}

.block-item-sublinks-card__lead {
    padding-bottom: 20px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);
}
.block-item-sublinks-card__overflow {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: v-bind(colorBackground);
    opacity: 0;
}
  </style>
