<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-tag-cloud is-narrow">
        <BlockTagCloud
          :title="title"
          :tags="computedTags?.length > 0 ? computedTags : tags"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
    },

    tags: {
        type: Array,
        default: () => [],
    },
});

const storyblokApi = useStoryblokApi();

const computedTags = ref([]);

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;
const filterStore = useFilterStore();
const tagList = computed(() => filterStore.getCloudTags);

const getTags = async () => {
    const { data } = await storyblokApi.get('cdn/stories', {
        starts_with: 'data-sources/tags',
        version: 'published',
        by_uuids: tagList.value.toString(),
        language,
    });

    computedTags.value = data.stories;

    // map tags for block
    computedTags.value = computedTags.value.map((tag) => ({
        name: tag.translated_slugs?.find(
            (slug) => slug.lang === currentLocale.value,
        )?.name || tag?.name,
        uuid: tag.uuid,
        color: tag.content.color,
        color_hover: tag.content.color_hover,
        size: tag.content.size,
    }));
};

onMounted(async () => {
    await getTags();
});

watch(tagList, async () => {
    await getTags();
});
</script>
<style lang="scss">

.storyblok-tag-cloud {
    @include default-content-columns;
    @include fluid('margin-bottom', 30px, 45px);
}

</style>
