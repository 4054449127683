<template>
  <div
    class="block-map-international"
    :class="{ 'is-hidden': isOpen }"
  >
    <div
      class="block-map-international__pinch-button"
      @click="togglePinch"
      @keypress="togglePinch"
    >
      <IonIcon v-show="pinchActive" class="block-map-international__pinch-button-icon" icon-name="move" />
      <IonIcon v-show="!pinchActive" class="block-map-international__pinch-button-icon" icon-name="lock" />
      <p>
        {{ useStoryblokTranslation('general', pinchActive ? 'activate' : 'deactivate') }}
      </p>
    </div>
    <div class="block-map-international__content-wrapper">
      <div
        class="block-map-international__map-wrapper"
        :class="{ 'is-wide': !isOpen }"
      >
        <AtomMap
          v-if="countries"
          :countries="countries"
          :latitude="latitude"
          :longitude="longitude"
          :class="{
            'is-pinch-active': pinchActive,
          }"
        />
      </div>
      <div
        :class="{ 'is-hidden': !isOpen }"
        class="block-map-international__swiper-wrapper"
      >
        <Swiper
          :modules="modules"
          slides-per-view="auto"
          :pagination="true"
          :grab-cursor="true"
          :navigation="{
            nextEl: '.block-map-international__controls--next',
            prevEl: '.block-map-international__controls--prev',
          }"
          @slide-change="onSlideChange"
          @swiper="onSwiper($event)"
        >
          <SwiperSlide
            v-for="(country) in countries"
            :key="country._uid"
            ref="slideRef"
            class="block-map-international__swiper"
          >
            <AtomImage
              v-if="country.content?.region?.content?.image?.filename"
              :image="country.content.region.content.image"
              class="block-map-international__slide-image"
              provider="storyblok"
            />
            <div class="block-map-international__slide-region">
              {{ country.content.region.name }}
            </div>
            <div class="block-map-international__slide-country">
              {{ country.name }}
            </div>
            <AtomLink
              v-if="country.content.link?.cached_url"
              class="block-map-international__slide-link"
              :link="country.content.link.cached_url"
              :text="country.content.label"
              icon="chevron-right"
            />
          </SwiperSlide>
          <div
            class="block-map-international__control block-map-international__controls--next"
            :class="{ 'is-hidden': hideNext }"
          >
            <div class="block-map-international__controls">
              <span
                v-if="countries"
                class="block-map-international__controls-number"
              >{{
                nextSlideIndex(countries.length, activeSlide)
              }}</span>
              <IonIcon icon-name="chevron-right" class="block-map-international__button-icon" />
            </div>
          </div>
          <div
            class="block-map-international__control block-map-international__controls--prev"
            :class="{ 'is-hidden': hidePrev }"
          >
            <div class="block-map-international__controls">
              <IonIcon icon-name="chevron-left" class="block-map-international__button-icon" />
              <span
                v-if="countries"
                class="block-map-international__controls-number"
              >{{
                prevSlideIndex(activeSlide)
              }}</span>
            </div>
          </div>
          <div
            class="block-map-international__close"
            @click="toggleOpen()"
            @keydown="toggleOpen()"
          >
            <IonIcon icon-name="close" />
          </div>
        </Swiper>
      </div>
      <div
        class="block-map-international__map-controls"
        :class="{ 'is-left': isOpen }"
      >
        <IonIcon icon-name="plus" class="block-map-international__zoom-in" />
        <IonIcon icon-name="minus" class="block-map-international__zoom-out" />
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    latitude: {
        type: Number,
        default: 0,
    },

    longitude: {
        type: Number,
        default: 0,
    },
});

const {
    Pagination,
    Navigation,
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const modules = [Navigation, Pagination];

const storyblokApi = useStoryblokApi();
const mapStore = useMapStore();
const countries = ref([]);
const activeCountryIndex = computed(() => mapStore.getActiveCountryIndex);
const slideRef = ref(null);
const swiper = ref(null);
const hideNext = ref(false);
const hidePrev = ref(true);
const activeSlide = ref(0);
const isOpen = computed(() => mapStore.getIsOpen);
const pinchActive = ref(true);

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const getCountries = async () => {
    const { data } = await storyblokApi.get('cdn/stories', {
        starts_with: 'data-sources/countries/',
        filter_query: {
            latitude: {
                is: 'not_empty',
            },
        },
        resolve_relations: 'Country.region',
        language,
    });

    countries.value = data.stories;
};

await getCountries();

const onSlideChange = (slide) => {
    mapStore.setActiveCountryIndex(slide.activeIndex);
    activeSlide.value = slide.activeIndex + 1;
};

const onSwiper = (e) => {
    swiper.value = e;
};

// watch activeCountryIndex and set swiper active index
watch(activeCountryIndex, (index) => {
    if (!isOpen.value) return;

    swiper.value.slideTo(index);
});

const prevSlideIndex = (slideNumber) => {
    if (slideNumber > 1) {
        hidePrev.value = false;
        return slideNumber - 1;
    }
    hidePrev.value = true;
    return '';
};
const nextSlideIndex = (sliderArrayLength, slideNumber) => {
    if (sliderArrayLength === slideNumber) {
        hideNext.value = true;
        return '';
    }
    hideNext.value = false;
    return slideNumber + 1;
};

const toggleOpen = () => {
    mapStore.setIsOpen(!isOpen.value);
};

const togglePinch = () => {
    pinchActive.value = !pinchActive.value;
};

onMounted(() => {
    mapStore.setIsOpen(false);
});
</script>

<style lang="scss">
.block-map-international {
    position: relative;
    display: block;

    .swiper {
        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            background: var(--c-background);
            opacity: 1;
        }
        .swiper-pagination-bullet-active {
            background: var(--c-coral-1)
        }
    }

    .swiper-horizontal >
    .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
        margin: 0 10px;

        @include mobile {
            margin: 0 6px;
        }
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 40px;
        left: 0;
        width: 100%;

        @include mobile {
            bottom: 35px;
            padding: 0 75px;
        }
    }

    .mapboxgl-ctrl-logo {
        opacity: 1;
        transition: opacity 0.3s ease;

    }

    &.is-hidden {
        .mapboxgl-ctrl-logo {
            opacity: 0;
        }
    }
}

.block-map-international__pinch-button {
    position: relative;
    display: none;
    min-height: 57px;
    align-items: center;
    padding: 15px 30px;
    padding-left: 95px;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-left: var(--page-padding);
    background: var(--c-coral-4);
    color: var(--c-white);
    column-gap: 30px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--button);
    line-height: var(--f-line-height--button);

    p {
        transform: translate(0px, 1px);
    }

    @include mobile {
        display: inline-flex;
    }
}

.block-map-international__pinch-button-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30px;
    margin: auto;
}

.block-map-international__content-wrapper {
    display: flex;
    width: 100%;
    height: 716px;

    @include mobile {
        height: 520px;
    }
}

.block-map-international__map-wrapper {
    width: 100%;
    height: 100%;
    transform: translateX(-30%);
    transition: transform 0.4s ease;

    &.is-wide {
        transform: translateX(0);
    }

    @include mobile {
        width: 100%;
        height: 100%;
        transform: translateX(0);
    }
}

.block-map-international__swiper-wrapper {
    position: absolute;
    right: 0;
    width: 560px;
    height: 100%;
    padding: 127px 0;
    animation: fadeIn 0.4s ease;

    &.is-hidden {
        display: none;
    }

    @include mobile {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 var(--page-padding);
    }
}

.block-map-international__swiper {
    padding: 40px 100px 128px 100px;
    background: var(--c-white);
    color: var(--c-coral-1);

    @include mobile {
        padding: 40px 25px 128px 25px;
    }
}

.block-map-international__slide-image {
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
}

.block-map-international__slide-region {
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--f-line-height--richtext);
}

.block-map-international__slide-country {
    margin-bottom: 20px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--f-line-height--h3);
}

.block-map-international__slide-link {
    width: fit-content;

    .atom-link__text {
        font-family: var(--f-family--bold);
        font-size: var(--f-size--richtext);
        line-height: var(--f-line-height--richtext);
    }
}

.block-map-international__control {
    @include z-index('header');

    position: absolute;
    bottom: 133px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: var(--c-background);
    color: var(--c-coral-1);
    cursor: pointer;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    outline: none;

    &.block-map-international__controls--next {
        right: 0;
        padding-left: 25px;
        border-bottom-left-radius: 38px;
        border-top-left-radius: 38px;

        .block-map-international__controls {
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        &.is-hidden {
            display: none;
        }
    }

    &.block-map-international__controls--prev {
        padding-right: 25px;
        border-bottom-right-radius: 38px;
        border-top-right-radius: 38px;

        .block-map-international__controls {
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        &.is-hidden {
            display: none;
        }
    }

    .block-map-international__controls-number {
        transform: translate(0px, 1px);
    }

    @include mobile {
        bottom: 20px;
        transform: translateY(0);
    }
}

.block-map-international__close {
    @include z-index('main');

    position: absolute;
    top: 40px;
    right: 36px;
    cursor: pointer;

    @include mobile {
        top: 20px;
        right: 20px;
    }
}

.block-map-international__map-controls {
    position: absolute;
    bottom: 0;
    left: 200px;
    display: flex;
    column-gap: 3px;
    transition: left 0.4s ease;

    &.is-left {
        left: 0;

        @include mobile {
            left: var(--page-padding);
        }
    }

    .block-map-international__zoom-in,
    .block-map-international__zoom-out {
        cursor: pointer;
    }

    @include tablet {
        left: 0;
    }

    @include mobile {
        left: var(--page-padding);
    }
}

.button-pinch-enter-active,
.button-pinch-leave-active {
    transition: all 0.5s ease;
}
.button-pinch-enter-from,
.button-pinch-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

@keyframes fadeIn {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}
</style>
