<template>
  <div
    class="atom-input atom-input__wrapper"
    :class="{ 'has-options-open': showOptions }"
  >
    <div ref="wrapperRef" class="atom-singleselect__wrapper">
      <div
        class="atom-singleselect"
        @keypress="toggleOptions(false)"
        @click="toggleOptions(false)"
      >
        <p class="atom-singleselect__label">
          {{ label }}
        </p>

        <IonIcon
          class="atom-singleselect__icon has-pointer is-right"
          icon-name="chevron-down"
        />
      </div>

      <div
        class="atom-singleselect__options-wrapper"
        :class="{ 'is-visible': showOptions }"
      >
        <div
          ref="optionsRef"
          class="atom-singleselect__options"
        >
          <div
            v-for="option in options"
            :key="`${option.value}-${option.type}`"
            class="atom-singleselect__option"
            :class="{ 'is-selected': singleselectedOptions.includes(option.value) }"
          >
            <button
              type="button"
              class="atom-singleselect__option-button"
              @click="singleselectOption(option)"
            >
              <span class="atom-singleselect__option-background" />
              <span class="atom-singleselect__option-label">{{ option.name }}</span>
              <IonIcon
                class="atom-singleselect__icon-close has-pointer is-right"
                icon-name="close"
              />
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="!showOptions"
        class="atom-singleselect__selected"
      >
        <div
          v-for="option in options"
          :key="option.value"
          class="atom-singleselect__selected-option"
          :class="{ 'is-selected': singleselectedOptions.includes(option.value) }"
        >
          <button
            type="button"
            class="atom-singleselect__selected-option-button"
            @click="singleselectOption(option)"
          >
            <span class="atom-singleselect__selected-option-background" />
            <span class="atom-singleselect__selected-option-label">{{ option.name }}</span>
            <IonIcon
              class="atom-singleselect__icon-close has-pointer is-right"
              icon-name="close"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/**
 * This component provides a singleselect input field with label, icon, and validation support.
 *
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {array} [props.options=[]] - An array of options to display in the singleselect field.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */

import { useWindowSize, onClickOutside } from '@vueuse/core';

const props = defineProps({
    /*
        General data
    */
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },
    type: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    options: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },
});

/*
    Intialze formfield
*/
const wrapperRef = ref(null);

/*
    Handle input
*/
const emit = defineEmits(['set-data']);

/*
    Custom Logic
*/
const optionsRef = ref(null);
const closedHeight = '4px';
const optionsHeight = ref(closedHeight);

/* handle singleselected options */
const singleselectedOptions = ref([]);

const handleData = (type) => {
    emit('set-data', {
        value: singleselectedOptions.value,
        name: props.name,
        uuids: singleselectedOptions.value[0] ? [singleselectedOptions.value[0]] : '',
        type,
    });
};

const singleselectOption = (option) => {
    // add option to singleselectedOptions
    if (!singleselectedOptions.value.includes(option.value)) {
        // add option.value + option.type to singleselectedOptions
        // there should only be one option selected
        singleselectedOptions.value = [];
        singleselectedOptions.value.push(option.value);
        handleData(option.type);
    } else {
        singleselectedOptions.value = singleselectedOptions.value.filter(
            (item) => item !== option.value,
        );

        handleData(option.type);
    }
};

/* show and hide options */
const showOptions = ref(false);
const toggleOptions = (forceClose = false) => {
    showOptions.value = forceClose ? false : !showOptions.value;
    optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
};

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    if (showOptions.value) {
        toggleOptions();
    }
});

/* Watch window width to set height of box accordingly on resize */
const { width } = useWindowSize();
watch(() => width.value, () => {
    if (!props.isFullScreen) {
        optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
    }
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color6 = computed(() => `var(--c-${colorTopLevel.value}-6)`);
const color11 = computed(() => `var(--c-${colorTopLevel.value}-11)`);
</script>

<style lang="scss" scoped>
.atom-input__wrapper {
    @include formFieldWrapper;

    user-select: none;
}
.atom-singleselect__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    cursor: pointer;
}

.atom-singleselect {
    @include formFieldBasicsText;

    position: relative;
    display: flex;
    width: 170px;
    max-width: 100%;
    height: 30px;
    align-items: flex-end;
    background: v-bind(color11);

    @include mobile {
        width: 100%;
    }
}

.atom-singleselect__label {
    @include formFieldLabelText;

    display: flex;
    align-items: flex-end;
    padding-top: 2px;
    padding-right: 38px;
    margin-bottom: 0;
    cursor: pointer;

    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    pointer-events: none;
}

.atom-singleselect__input {
    @include formFieldMultiselect;

    pointer-events: none;
}

.atom-singleselect__icon {
    @include formFieldInputIcon(false);

    top: 5px;
    right: 6px !important;
    width: 22px;
    height: 22px;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    .has-options-open & {
        transform: rotate(180deg);
    }
}
.atom-singleselect__trigger {
    @include z-index('selectTrigger');

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
}

.atom-singleselect__options-wrapper {
    @include z-index('selectTrigger');

    position: absolute;
    top: 30px;
    width: 100%;

    &.is-visible {
        display: block;
    }
}

.atom-singleselect__options {
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: height 0.5s $EASE_OUT--QUINT;

    .has-options-open & {
        height: 100%;
    }
}

.atom-singleselect__option {

    position: relative;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-singleselect__selected-option {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-top: 3px;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-singleselect__option-divider {
    display: block;
    width: calc(100% - 40px);
    height: 2px;
    margin: 0 auto;
}

.atom-singleselect__option-button {
    display: flex;
    width: 100%;

    align-items: center;
    justify-content: flex-end;
}

.atom-singleselect__selected-option-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.atom-singleselect__option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(color11);
    transition: background-color 0.2s ease-in-out;
}

.atom-singleselect__selected-option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(color11);
    transition: background-color 0.2s ease-in-out;
}

.atom-singleselect__option-label {
    @include z-index('selectLabel');

    position: relative;
    margin: 6px 36px 6px 10px;
    color: v-bind(color6);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);

    .is-selected & {
        color: v-bind(color1);
    }
}

.atom-singleselect__selected-option-label {
    @include z-index('selectLabel');
    position: relative;
    padding: 6px 36px 6px 10px;
    color: v-bind(color6);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);

    .is-selected & {
        color: v-bind(color1);
    }
}

.atom-singleselect__icon-close {
    @include formFieldInputIcon(false);
    @include z-index('selectLabel');

    top: 8px;
    right: 10px !important;
    width: 14px;
    height: 14px;
    opacity: 0;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    .is-selected & {
        right: 10px;
        opacity: 1;
    }

}

.atom-singleselect__selected {
    .atom-singleselect__selected-option {
        display: none;

        &.is-selected {
            display: flex;
        }
    }
}
</style>
