<template>
  <div class="block-accordion">
    <AtomItemAccordion
      v-for="item of items"
      :key="item._uid"
      :title="item.title"
    >
      <template #content>
        <div class="block-accordion__content">
          <AtomRichtext
            v-if="item.text"
            :html="item.text"
            class="block-accordion__paragraph"
          />
          <AtomLink
            v-if="item?.link"
            class="block-accordion__cta"
            :link="item.link"
            :text="item.linkLabel"
            :icon="item.isInternal ? 'chevron-right' : 'arrow-up-right'"
          />
        </div>
      </template>
    </AtomItemAccordion>
  </div>
</template>

<script setup>

defineProps({
    items: {
        type: Array,
        required: true,
        validator: (value) => value.isArray && value.length > 0,
    },
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
.block-accordion {
    color: v-bind(color1);
}
.block-accordion__content {
    @include fluid('margin-left', 15px, 20px);
    @include fluid('margin-right', 20px, 30px);
    max-width: 839px;
}
.block-accordion__content > :last-child {
    padding-bottom: 26px;
}
.block-accordion__paragraph {
    padding-bottom: 20px;
    margin-top: 4px;
    font-family: var(--f-family--thin);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    .is-open & {
        opacity: 1;
    }
}
.block-accordion__cta {

    padding-bottom: 20px;
    @include mobile {
        padding-right: 60px;
    }
}
</style>
